<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <span class="filter">
    <v-menu
      :close-on-content-click="false"
      v-model="filterActive"
      :nudgeBottom="20"
      :offsetY="true"
      ref="menu"
      :disabled="disabled"
      :data-qa="filterName + '-filter-menu'"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon flat small v-on="on" :disabled="disabled">
          <v-icon
            small
            :class="{ active: filterSelect.length !== 0 && !disabled }"
            :data-qa="filterName + '-filter-icon'"
          >
            filter_list
          </v-icon>
        </v-btn>
      </template>
      <v-combobox
        v-model="filterSelect"
        :items="translatedFilter"
        item-value="code"
        item-text="name"
        multiple
        chips
        deletable-chips
        :data-qa="filterName + '-filter-select'"
        class="filter-options"
        ref="comboChild"
      >
      </v-combobox>
    </v-menu>
  </span>
</template>

<script>
import store from '@/store';
import { capitalize } from 'lodash';

export default {
  name: 'VehicleFilter',
  props: {
    filterItems: Array,
    filterName: String,
    isCapital: String,
    translate: Boolean,
    translateRoot: {
      type: String,
      default: '',
      required: false,
    },
    keyName: String,
    valueName: String,
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  mounted() {
    this.$watch('$refs.menu.hasJustFocused', (isVisible) => {
      if (isVisible) {
        this.$refs.comboChild.focus();
        this.$refs.comboChild.activateMenu();
        this.replaceBlankVehicleFilters(this.filterItems);
      }
    });
  },
  computed: {
    filterSelect: {
      get: function () {
        let value =
          store.getters['vehicle/vehicleFilter'][this.filterName] || [];
        if (value !== []) {
          this.replaceBlankVehicleFilters(this.filterItems);
        }
        return value.map((val) => {
          return {
            code: val,
            name: this.translatedCodeToNameMap[val],
          };
        });
      },
      set: async function (updatedFilterSelect) {
        this.reset();
        await this.$store.dispatch('vehicle/setVehicleFilter', {
          name: this.filterName,
          selected: updatedFilterSelect.map((f) => f.code),
        });
      },
    },
  },
  data: () => {
    return {
      filterActive: false,
      translatedFilter: [],
      translatedCodeToNameMap: {},
    };
  },
  methods: {
    capitalize,
    reset() {
      // The combobox has a bug where the search text is not cleared after the user selects an item.
      // issue: https://github.com/vuetifyjs/vuetify/issues/11804
      // The workaround of adding an on-change event to clear the search causes a side effect
      // of selecting another item in the list (an item that was not selected).
      // I found that delaying to clear the search fixes the issue.
      setTimeout(() => {
        this.$refs.comboChild.lazySearch = '';
      }, 10);
    },
    replaceBlankVehicleFilters(filters) {
      if (filters) {
        let keyName = this.keyName;
        let valueName = this.valueName;

        if (keyName && valueName) {
          this.translatedFilter = filters.map((e) => {
            return { code: e[keyName], name: e[valueName] };
          });
          this.translatedFilter.sort((a, b) => (a.name > b.name ? 1 : -1));
        } else {
          this.translatedFilter = filters.map((e) => {
            if (e === null) {
              return {
                code: 'vehicle.filter.blankValue',
                name: this.$t('vehicle.filter.blankValue'),
              };
            } else if (this.translate) {
              return { code: e, name: this.$t(this.translateRoot + e) };
            }
            return { code: e, name: e };
          });
        }

        if (this.filterName === 'consentedConsumers') {
          this.translatedFilter.unshift({
            code: 'vehicle.readyForConsent',
            name: this.$t('vehicle.readyForConsent'),
          });
        }

        if (this.isCapital === true) {
          this.translatedFilter = this.translatedFilter.map((e) => {
            return {
              code: this.capitalize(e.code),
              name: this.capitalize(e.name),
            };
          });
        }

        this.translatedFilter.forEach((e) => {
          this.translatedCodeToNameMap[e.code] = e.name;
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-datatable {
  thead {
    th.column.sortable {
      &.desc {
        .filter {
          .v-icon {
            transform: none;
          }
        }
      }

      .v-icon {
        opacity: 1;
        color: rgba(0, 0, 0, 0.38);

        &.active {
          color: rgba(0, 0, 0, 0.87);
        }
      }
    }
  }
}

::v-deep .filter-options {
  .v-input__slot {
    height: auto !important;
    padding-left: 4px;
  }
}

.v-autocomplete {
  min-width: 350px;
  max-width: 350px;
}

.filter-options {
  background-color: white;
  padding-top: 0;
  margin-top: 0;
}

.v-menu__content {
  &.menuable__content__active [role='list'] {
    transform: translateY(40px);
  }
}

[role='combobox'].v-text-field {
  margin: 0;
  padding: 0;

  .v-text-field__details {
    display: none;
  }
}

::v-deep .v-list__tile__action {
  .v-input--selection-controls {
    .v-input__slot {
      margin-bottom: 0px;
    }
  }
}
</style>
