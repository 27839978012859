<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-form ref="form" style="width: 100%">
    <v-dialog
      v-model="dialog"
      scrollable
      @keydown.esc="cancel()"
      :data-qa="`${dataQaKey}-confirmation-dialog`"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template v-slot:activator="data">
        <block-button
          @click="openDialog"
          :data-qa="activator.dataQaKey"
          :color="activator.color"
          :disabled="activator.disabled"
          ref="activatorButton"
        >
          {{ activator.text }}
        </block-button>
      </template>
      <v-card data-qa="confirmation-dialog-dropdown">
        <v-card-title>
          <h1 class="title primary--text">
            <slot name="title"></slot>
          </h1>
        </v-card-title>
        <v-card-text data-qa="confirmation-dialog-message">
          <p class="body-1" v-html="message"></p>
        </v-card-text>
        <v-divider />
        <v-card-text
          :id="`${dataQaKey}-confirmation-dialog-dropdown-content`"
          data-qa="confirmation-dialog-dropdown-content"
          :style="{ height: height }"
        >
          <slot name="content"></slot>
        </v-card-text>
        <v-divider />
        <v-card-text
          class="dropdown-list"
          :data-qa="`${dataQaKey}-list`"
          height="40px"
        >
          <MultiSelectDropdown
            v-if="isMultiSelect"
            :model="selectedItems"
            :items="dropDownItems"
            :name="`${dashify(title)}-multi-select-list`"
            :select-all-label="$t('vehicle.addVehicle.group.selectAll')"
            :label="label"
            :chip-count="3"
            @dropdown-value-changed="selectedItems = $event"
          />
          <v-select
            v-else
            :items="dropDownItems"
            item-text="name"
            item-value="id"
            data-qa="dropdown-list"
            :rules="itemRule"
            :label="label"
            height="40px"
            v-model="selectedItem"
            outline
            required
            @change="selectedItemChanged($event)"
          >
            <template v-slot:item="data">
              <div :data-qa="`item-name-${dashify(data.item.name)}`">
                {{ data.item.name }}
              </div>
            </template>
            <template v-slot:selection="data">
              <div :data-qa="`${dashify(data.item.name)}-name`">
                {{ data.item.name }}
              </div>
            </template>
          </v-select>
        </v-card-text>
        <v-card-text>
          <slot name="dropdownFootnote"></slot>
        </v-card-text>
        <v-card-actions>
          <cancel-button
            data-qa="confirmation-dialog-dropdown-cancel"
            @click="cancel()"
          >
            <slot name="cancel-text"></slot>
          </cancel-button>
          <confirm-button
            data-qa="confirmation-dialog-dropdown-confirm"
            :disabled="isConfirmDisabled"
            @click="confirm"
          >
            <slot name="confirm-text"></slot>
          </confirm-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import dashify from 'dashify';
import MultiSelectDropdown from '@/components/MultiSelectDropdown';
import CancelButton from '@/components/utility-components/dialogs/CancelButton';
import ConfirmButton from '@/components/utility-components/dialogs/ConfirmButton';
import BlockButton from '@/components/utility-components/BlockButton';

export default {
  name: 'ConfirmationDialogWithDropDownAndActivator',
  components: {
    BlockButton,
    ConfirmButton,
    CancelButton,
    MultiSelectDropdown,
  },
  data() {
    return {
      showDialog: false,
      selectedItem: '',
      selectedItems: [],
    };
  },
  computed: {
    dialog: {
      get: function () {
        return this.showDialog || this.enableDialog;
      },
      set: function (v) {
        this.showDialog = v;
      },
    },
    isConfirmDisabled: function () {
      if (this.isMultiSelect) {
        return (
          this.selectedItems === undefined ||
          this.selectedItems === null ||
          this.selectedItems.length === 0
        );
      }
      return (
        this.selectedItem === undefined ||
        this.selectedItem === '' ||
        this.disableConfirm
      );
    },
  },
  methods: {
    dashify,
    openDialog() {
      if (!this.showDialog) {
        this.showDialog = true;
      }
    },
    setDefaultSelectedItem() {
      if (this.preselectedItem !== '' && this.preselectedItem != null) {
        this.selectedItem = this.preselectedItem;
      } else if (
        this.defaultWhenOneOption &&
        this.dropDownItems != null &&
        this.dropDownItems.length > 0
      ) {
        this.selectedItem = this.dropDownItems[0].id;
      } else {
        this.selectedItem = '';
        this.$refs.form.reset();
      }
    },
    setAllItemsSelected() {
      if (this.isMultiSelect && this.allItemsPreSelected) {
        this.selectedItems = this.dropDownItems.map((item) => item.id);
      }
    },
    selectedItemChanged($event) {
      this.$emit('selectedItemChanged', $event);
    },
    cancel() {
      this.$emit('cancel');
      this.revertFocus();
      this.clearForm();
    },
    revertFocus() {
      if (this.$refs.activatorButton) {
        setTimeout(() => {
          this.$refs.activatorButton.$el.focus();
        }, 100);
      }
    },
    confirm() {
      if (this.isMultiSelect) {
        let selectedGroups = [];
        for (let item of this.dropDownItems) {
          if (this.selectedItems.includes(item.id)) {
            selectedGroups.push(item);
          }
        }
        this.$emit('confirm', selectedGroups);
      } else {
        for (let item of this.dropDownItems) {
          if (item.id === this.selectedItem) {
            this.$emit('confirm', item);
            break;
          }
        }
      }
      this.clearForm();
    },
    clearForm() {
      if (this.clearFormOnCancel) {
        this.selectedItem = '';
        this.$refs.form.reset();
      }
      this.dialog = false;
    },
  },
  props: {
    message: String,
    label: String,
    activator: {
      text: String,
      dataQaKey: String,
      color: String,
      disabled: Boolean,
    },
    height: {
      type: String,
      default: '200px',
    },
    enableDialog: {
      type: Boolean,
      default: false,
    },
    dropDownItems: Array,
    itemRule: Array,
    defaultWhenOneOption: {
      type: Boolean,
      default: false,
    },
    disableConfirm: {
      type: Boolean,
      default: false,
    },
    clearFormOnCancel: {
      type: Boolean,
      default: true,
    },
    preselectedItem: {
      type: String,
      default: '',
    },
    isMultiSelect: {
      type: Boolean,
      default: false,
    },
    dataQaKey: String,
    allItemsPreSelected: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dropDownItems() {
      this.setDefaultSelectedItem();
      this.setAllItemsSelected();
    },
    showDialog(value) {
      if (value) {
        this.setDefaultSelectedItem();
      }
    },
  },
  mounted() {
    this.setDefaultSelectedItem();
    this.setAllItemsSelected();
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  max-width: 480px;
}

.dropdown-list {
  padding-top: 16px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 0;
}

::v-deep .v-messages {
  min-height: 0;
}

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0;
}

.v-dialog__container {
  display: none !important;
}

::v-deep .v-text-field .v-input__slot {
  height: initial !important;
  border: initial !important;
}

div.v-input.v-text-field.v-select:focus-within {
  outline: 2px solid var(--v-secondary-base);
  outline-offset: 5px;
}
</style>
