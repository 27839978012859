import Vue from 'vue';
import AccountApiProxyService from '@/services/AccountApiProxyService';
import { AccountConstants } from '@/constants';

export default new Vue({
  methods: {
    updateSetting(url, requestBodyField, newValue) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + url,
        this.createUpdateRequestBody(requestBodyField, newValue),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    },
    updateSettingValue(url, newValue) {
      return AccountApiProxyService.post(
        AccountConstants.ACCOUNT_API_PATH + url,
        newValue,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    },
    getSetting(url) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH + url
      );
    },
    getUserLanguage(userId) {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/v1/settings/user-language/' +
          userId
      );
    },
    createUpdateRequestBody(requestBodyField, value) {
      return '{"' + requestBodyField + '": ' + '"' + value + '"}';
    },
    getNotificationPreferences() {
      return AccountApiProxyService.get(
        AccountConstants.ACCOUNT_API_PATH +
          '/v1/settings/notification/preferences'
      );
    },
  },
});
