<!--suppress CssInvalidPseudoSelector -->
<template>
  <v-navigation-drawer
    data-qa="sidebar"
    clipped
    touchless
    app
    class="white elevation-5"
    :mini-variant="drawer.mini"
    width="240px"
    permanent
    :style="{ marginTop: headerHeight + 'px' }"
  >
    <v-layout column>
      <v-list data-qa="navigation-items">
        <span
          :key="item.title"
          v-for="item in visibleItems"
          :data-qa="'navigation-item-' + dashify(item.title)"
        >
          <v-list-tile
            v-if="isSubItem(item)"
            :to="resolvePath(item.path, item.querystring)"
            :data-qa="'sidebar-subitem-' + dashify(item.title)"
            :class="{
              'list-item': true,
              'font-weight-regular': true,
              'mr-2': true,
              'font-weight-medium': isItemActive(item.path),
              highlighted: isItemActive(item.path),
            }"
            style="margin-left: 65px"
            @click="analyticsService.doTrackUserEvents(item.title)"
          >
            <v-list-tile-content>
              <v-list-tile-title class="list-item-title">
                <p class="primary--text">{{ item.title }}</p>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile
            v-else
            :to="resolvePath(item.path, item.querystring)"
            :data-qa="'sidebar-item-' + dashify(item.title)"
            :class="{
              'list-item': true,
              'font-weight-medium': true,
              'mx-2': true,
              highlighted: isItemActive(item.path),
            }"
            @click="analyticsService.doTrackUserEvents(item.title)"
          >
            <v-list-tile-action>
              <v-icon class="list-item-icon primary--text" size="22pt">{{
                item.icon
              }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title
                class="list-item-title subheading font-weight-medium"
              >
                <p class="primary--text">{{ item.title }}</p>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </span>
      </v-list>
      <v-layout align-end row>
        <v-flex>
          <v-layout column align-end>
            <v-flex>
              <v-btn
                flat
                :class="{ collapsed: drawer.mini }"
                v-if="!drawer.displayCollapse"
                color="primary--darken-2"
                class="collapse-button outline-revert"
                data-qa="collapse-button"
                @click="toggleCollapse"
              >
                <v-icon class="body-1 mr-1 primary--text font-weight-medium"
                  >ic-collapse</v-icon
                >
                <span
                  v-show="!drawer.mini"
                  class="body-1 text-none primary--text font-weight-medium"
                  >{{ $t('sidebar.collapse') }}</span
                >
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import dashify from 'dashify';
import { mapGetters } from 'vuex';
import AnalyticsService from '@/services/AnalyticsService';
import { HeaderHeight } from '@/mixins/HeaderHeight';

export default {
  name: 'TheSidebar',
  data() {
    return {
      drawer: {
        mini: false,
        displayCollapse: true,
      },
      analyticsService: AnalyticsService,
    };
  },
  props: {
    items: {
      required: true,
    },
  },
  filters: {
    shorten: function (str, length) {
      return str <= length ? str : str.substr(0, length - 1) + '…';
    },
  },
  mixins: [HeaderHeight],
  computed: {
    ...mapGetters('auth', ['getUserInfo']),
    visibleItems: function () {
      return this.getVisibleItems(this.$route.path);
    },
  },
  mounted() {
    this.setDrawerMini(this.$vuetify.breakpoint.mdAndDown);
    this.setDisplayCollapse(this.$vuetify.breakpoint.smAndDown);
  },
  methods: {
    isItemActive(itemRoute) {
      return this.$route.matched.some((record) => record.regex.test(itemRoute));
    },
    isSubItem(item) {
      return !!item.group && !item.groupHead;
    },
    isVisible(item, matchedNavItem) {
      if (item.userVisible) {
        if (item.groupHead) return true;
        if (item.group === undefined) return true;
        if (
          typeof matchedNavItem.group !== 'undefined' &&
          item.group === matchedNavItem.group
        )
          return true;
      }
      return false;
    },
    getVisibleItems(path) {
      let matchedItems = this.items.filter((it) => it.path === path);
      let matchedNavItem = matchedItems.length === 0 ? {} : matchedItems[0];
      return this.items.filter((item) => this.isVisible(item, matchedNavItem));
    },
    toggleCollapse() {
      this.drawer.mini = !this.drawer.mini;
    },
    setDrawerMini(isMini) {
      this.drawer.mini = isMini;
    },
    setDisplayCollapse(display) {
      this.drawer.displayCollapse = display;
    },
    checkBreakpoint(name) {
      switch (name) {
        case 'lg':
        case 'xl':
          this.setDrawerMini(false);
          break;
        case 'xs':
        case 'sm':
        case 'md':
          this.setDrawerMini(true);
      }
    },
    dashify(str, options) {
      return dashify(str, options);
    },
    resolvePath(path, querystring) {
      if (querystring != null) {
        return path + '?' + querystring;
      }
      return path;
    },
  },
  watch: {
    '$vuetify.breakpoint.mdAndDown': function (value) {
      this.setDrawerMini(value);
    },
    '$vuetify.breakpoint.smAndDown': function (value) {
      this.setDisplayCollapse(value);
      this.setDrawerMini(this.$vuetify.breakpoint.mdAndDown);
    },
  },
};
</script>

<style scoped lang="scss">
$roundedBorder: 4px;
$tileBackground: #102b4e;
$hoverColor: #f3f4f6;

::v-deep .list-item {
  border-radius: $roundedBorder;

  &:hover,
  &:active {
    background-color: $hoverColor;
  }

  a {
    height: 3.5em;
  }
}

.highlighted {
  background-color: $tileBackground;
  border-radius: $roundedBorder;

  p {
    color: white !important;
  }

  i {
    color: white !important;
  }

  &:hover {
    background-color: $tileBackground;

    p {
      color: white !important;
    }

    i {
      color: white !important;
    }
  }
}

.v-navigation-drawer--mini-variant .v-list__tile__action {
  justify-content: initial;
}

.collapse-button {
  &.v-btn {
    min-width: 0;
  }

  &.collapsed i {
    transform: rotate(180deg);
  }
}
</style>
