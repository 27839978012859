<!--suppress CssInvalidPseudoSelector -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <DataGridDrawer
    @close-dialog="hideDialogOnClick"
    :show-dialog="dialog"
    data-qa-key="vehicle"
    :header="$t('vehicle.editVehicle.header')"
    class="edit-dialog"
    :value-changed="isModified"
    :update-button-text="$t('vehicle.editVehicle.update')"
    :cancel-button-text="$t('vehicle.editVehicle.cancel')"
    @drawer-update-action="editVehicle"
  >
    <template v-slot:staticContent>
      <v-container class="edit-vehicle-container">
        <v-form data-qa="edit-vehicle-form" v-model="valid" ref="form">
          <VehicleInfoDialogue
            :vehicle-details="vehicleEditDetails"
            @vehicle-info-changed="valueChangedInDrawer"
          />
        </v-form>

        <v-divider class="mt-4" />
        <v-container
          class="vehicle-products-list px-0"
          data-qa="vehicle-products-list"
        >
          <v-layout row xs12 align-center justify-space-between>
            <v-flex>
              <span class="headline">
                {{ $t('vehicle.editVehicle.products.title') }}
              </span>
            </v-flex>
            <v-flex shrink class="pr-0">
              <ConfirmationDialogWithDropDownAndActivator
                ref="subscribeSingleProduct"
                :drop-down-items="capableProducts"
                :label="$t('vehicle.toolbar.subscribeProducts.label')"
                :message="
                  $t('vehicle.toolbar.subscribeProducts.message', {
                    nVehicles: $tc('vehicle.nVehicles', 1, {
                      numberOfVehicles: 1,
                    }),
                  })
                "
                :data-qa-key="`${uniqueId}-vehicle-edit-subscribe-products`"
                @confirm="attemptSubscribe($event)"
                :activator="{
                  text: subscribeProductsLabel,
                  dataQaKey: 'subscribe-to-product',
                  color: 'primary',
                  disabled: capableProducts.length < 1,
                }"
              >
                <template v-slot:title>
                  {{ $t('vehicle.toolbar.subscribeProducts.title') }}
                </template>
                <template v-slot:content>
                  <p>
                    {{
                      vehicleDetails.name
                        ? vehicleDetails.name
                        : vehicleDetails.vin
                    }}
                  </p>
                </template>
                <template v-slot:confirm-text>
                  {{ $t('vehicle.toolbar.subscribeProducts.acceptanceText') }}
                </template>
              </ConfirmationDialogWithDropDownAndActivator>
            </v-flex>
          </v-layout>

          <v-layout
            row
            v-if="loading || loadingConsents"
            justify-center
            align-center
          >
            <loading-spinner />
          </v-layout>
          <v-layout
            row
            v-else-if="
              capabilityCheckComplete &&
              vehicleDetails.products.length + capableProducts.length < 1
            "
          >
            <div class="pl-1" data-qa="incapable-description">
              {{ $t('vehicle.editVehicle.products.notCapable') }}
            </div>
          </v-layout>
          <v-layout
            v-else
            class="py-2 pl-3 mb-2"
            align-center
            row
            v-for="(product, index) in vehicleDetails.products"
            :key="product.productSku"
          >
            <v-layout column>
              <v-layout class="align-baseline" row>
                <v-flex class="pb-0" xs1>
                  <VehicleStatusIcon :status="product.enrollmentStatus" />
                </v-flex>
                <v-flex class="title pb-0" xs9>
                  <p class="mb-0">{{ $t(product.name) }}</p>
                </v-flex>
              </v-layout>
              <v-layout class="align-baseline" row>
                <v-flex class="pt-0" offset-xs1 xs9>
                  <div>
                    <p
                      :data-qa="`status-date-${dashify(product.productSku)}`"
                      class="mb-0 heading"
                      style="font-weight: 500"
                    >
                      {{
                        $t(
                          'vehicle.editVehicle.products.status.new.' +
                            product.enrollmentStatus,
                          { date: formatDate(product.statusDate) }
                        )
                      }}
                    </p>
                    <p
                      v-if="
                        displayExpirationDate(
                          product.statusDate,
                          product.subscriptionServiceActivationDate,
                          complimentaryBevTrialDays
                        )
                      "
                      :data-qa="`expiration-date-${dashify(
                        product.productSku
                      )}`"
                      class="mb-0 heading"
                    >
                      {{ $t('products.complimentaryProducts.expire') }}
                      {{
                        formatDate(product.subscriptionServiceActivationDate)
                      }}
                    </p>
                    <ErrorBucket
                      v-if="displayErrorBucket(product)"
                      data-qa="additional-error-information"
                      :error-bucket="product.errorBucket"
                      :error-code="product.enrollmentMessageCode"
                    />
                  </div>
                </v-flex>
                <v-flex xs2>
                  <OTAActionItem
                    v-if="
                      shouldShowOTAToggle(
                        product.productSku,
                        vehicleDetails.otaBlockStatus,
                        product.enrollmentStatus
                      )
                    "
                    :disabled="shouldDisableOTAToggle(product.enrollmentStatus)"
                    class="ota-switch"
                    data-qa="vehicle-edit-ota-block"
                    :disabledText="$t('ota.action.label.unblocked')"
                    :enabledText="$t('ota.action.label.blocked')"
                    :item="vehicleDetails.otaBlockStatus"
                  />
                  <template
                    v-else-if="
                      ['UNENROLLMENT_ALERT', 'ENROLLMENT_ALERT'].includes(
                        product.enrollmentStatus
                      )
                    "
                  >
                    <v-btn
                      class="text-none mr-0 remove_design outline-revert"
                      style="color: #2c64a5"
                      @click="retryEnrollmentAction(product)"
                    >
                      {{ $t('vehicle.editVehicle.products.retry') }}
                    </v-btn>
                    <v-btn
                      v-if="
                        ['ENROLLMENT_ALERT'].includes(
                          product.enrollmentStatus
                        ) && showFailedEnrollmentCancelButton
                      "
                      class="text-none mr-0 remove_design outline-revert"
                      style="color: #df4d37"
                      @click="
                        cancelEnrollmentFailedStatus(
                          product,
                          vehicleDetails.vin
                        )
                      "
                    >
                      {{ $t('vehicle.editVehicle.products.cancel') }}
                    </v-btn>
                  </template>
                  <ConfirmationDialogWithDropDown
                    v-else
                    :ref="'unsubscribeSingleProduct-' + index"
                    :acceptance-text="
                      $t('vehicle.toolbar.unsubscribeProducts.acceptanceText')
                    "
                    :cancellation-text="$t('confirmationDialog.cancel')"
                    :clear-form-on-cancel="false"
                    :data-qa-key="'vehicle-toolbar-unsubscribe-products'"
                    :drop-down-items="[
                      {
                        name: product.name,
                        id: product.productSku,
                      },
                    ]"
                    :label="$t('vehicle.toolbar.unsubscribeProducts.label')"
                    :message="productUnsubscribeMessage"
                    :title="$t('vehicle.toolbar.unsubscribeProducts.title')"
                    default-when-one-option
                    @confirm="attemptUnsubscribe($event)"
                    @cancel="cancelUnsubscribeDialog(index)"
                  >
                    <template v-slot:content>
                      <p>
                        {{
                          vehicleDetails.name
                            ? vehicleDetails.name
                            : vehicleDetails.vin
                        }}
                      </p>
                    </template>

                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        :color="
                          product.enrollmentStatus !== 'ENROLLED'
                            ? 'info'
                            : 'error'
                        "
                        :data-qa="`product-remove-${dashify(
                          product.productSku
                        )}`"
                        :disabled="product.enrollmentStatus !== 'ENROLLED'"
                        class="text-none mr-0 remove_design outline-revert"
                        @click="setSelectedProductFamilyId(product.productSku)"
                        flat
                        :ref="'unsubscribeSingleProductButton-' + index"
                      >
                        {{ $t('vehicle.editVehicle.products.remove') }}
                      </v-btn>
                    </template>
                  </ConfirmationDialogWithDropDown>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-layout>
        </v-container>

        <v-divider class="mt-4" v-if="canViewGroups()" />

        <v-container
          class="vehicle-groups-list px-0"
          data-qa="vehicle-groups-list"
          v-if="canViewGroups()"
        >
          <v-layout row xs12 align-center justify-space-between>
            <v-flex>
              <span class="headline">
                {{ $t('vehicle.editVehicle.groups.title') }}
              </span>
            </v-flex>
          </v-layout>
          <v-layout row v-if="loading" justify-center align-center>
            <loading-spinner />
          </v-layout>

          <v-layout
            v-else
            class="py-1"
            align-center
            row
            v-for="group in vehicleDetails.segments"
            :key="group.segmentId"
          >
            <v-flex xs12>
              <v-layout column>
                <v-flex xs2 :data-qa="'vehicle-group-' + group.segmentId">
                  {{ group.name }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider class="mt-4" v-if="canLinkEquipment()" />
        <v-container
          class="vehicle-groups-list px-0"
          data-qa="vehicle-groups-list"
          v-if="canLinkEquipment()"
        >
          <v-layout row xs12 align-center justify-space-between>
            <v-flex>
              <span class="headline titleCase">
                {{ $t('equipment.linkedEquipment').toLowerCase() }}
              </span>
            </v-flex>
          </v-layout>
          <v-layout
            row
            v-if="loading || loadingLinkedAssets"
            justify-center
            align-center
          >
            <loading-spinner />
          </v-layout>
          <LinkAssetInfo
            v-else
            :current-entity="vehicleEditDetails"
            :linked-assets="linkedAssets"
            :equipment-types="equipmentTypes"
          >
          </LinkAssetInfo>
        </v-container>
      </v-container>
    </template>

    <template v-slot:action>
      <v-layout row>
        <v-flex>
          <v-layout row>
            <v-flex xs12 sm6 />
            <v-flex xs12 sm6>
              <ConfirmationDialogWithActivatorButton
                ref="disableVehicleConfirmation"
                :data-qa-key="`${uniqueId}-vehicle-toolbar-disable-vehicle`"
                :activator="{
                  text: $t('vehicle.editVehicle.decommission'),
                  dataQaKey: 'vehicle-disable',
                }"
                :display-confirm="confirmationDialog.displayConfirm"
                height="100px"
                @confirm="determineDisableConflict"
              >
                <template v-slot:title>
                  {{ confirmationDialog.confirmationTitle }}
                </template>
                <template v-slot:message>
                  <div v-html="confirmationDialog.confirmationMessage" />
                </template>
                <template v-if="showVehicleListInDialog" v-slot:content>
                  <div
                    v-bind:key="vehicle.vin"
                    v-for="vehicle in consentedVehicles"
                  >
                    <VehicleEditDialogListDetails :vehicle-consent="vehicle" />
                  </div>
                </template>
                <template v-else v-slot:content>
                  <p>
                    {{
                      vehicleDetails.name
                        ? vehicleDetails.name
                        : vehicleDetails.vin
                    }}
                  </p>
                </template>
                <template v-slot:activator v-if="canViewMarkForDelete()" />
                <template v-slot:confirm-text>
                  {{ confirmationDialog.acceptanceText }}
                </template>
                <template v-slot:cancel-text>
                  {{ confirmationDialog.cancelText }}
                </template>
              </ConfirmationDialogWithActivatorButton>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <ErrorDialog
        :error="error"
        :show-dialog="showErrorDialog"
        @close-dialog="showErrorDialog = false"
      />
      <ConfirmationDialog
        :acceptance-text="conflictAcceptanceText()"
        :cancellation-text="$t('confirmationDialog.goBack')"
        :data-qa-key="`${uniqueId}-vehicle-toolbar-conflict`"
        :enable-dialog="confirmWhenConflict"
        :message="conflictMessage()"
        :title="conflictTitle()"
        data-qa="confirmation-dialog-conflict"
        persistent
        @cancel="
          confirmWhenConflict = false;
          reshowProductDialog();
        "
        @confirm="conflictConfirmAction()"
      >
        <template
          v-if="consentedVehicles.length > 0 && markForDeletion"
          v-slot:content
        >
          <div v-bind:key="vehicle.vin" v-for="vehicle in consentedVehicles">
            <VehicleEditDialogListDetails :vehicle-consent="vehicle" />
          </div>
        </template>
        <template v-else v-slot:content>
          <p
            v-bind:key="vehicle.vehicle.vehicleId"
            v-for="vehicle in conflictingVehicles"
          >
            {{ vehicle.vehicle.displayName }}
          </p>
        </template>
      </ConfirmationDialog>
      <ProcessingDialog
        :show-processing="showProcessing"
        :message="processingMessage"
      />
    </template>
  </DataGridDrawer>
</template>

<script>
import VehicleService from '@/services/VehicleService';
import { cloneDeep } from 'lodash';
import { AccountConstants } from '@/constants';
import ConfirmationDialog from '@/components/utility-components/dialogs/ConfirmationDialog';
import ErrorDialog from '@/components/utility-components/dialogs/ErrorDialog';
import ErrorMessageService from '@/services/ErrorMessageService';
import dashify from 'dashify';
import { mapActions, mapGetters, mapState } from 'vuex';
import VehicleStatusIcon from '@/components/VehicleStatusIcon';
import ProcessingDialog from '@/components/ProcessingDialog';
import ConsentService from '@/services/ConsentService';
import OTAService from '@/services/OTAService';
import OTAActionItem from '@/components/ota/OTAActionItem';
import SubscribeToStore from '@/mixins/SubscribeToStore';
import moment from 'moment';
import { get } from 'vuex-pathify';
import DataGridDrawer from '@/components/grid/DataGridDrawer';
import VehicleInfoDialogue from '@/components/VehicleInfoComponent';
import ErrorBucket from '@/components/error/ErrorBucket';
import LinkAssetInfo from '@/components/equipment/LinkAssetInfo';
import EquipmentService from '@/services/EquipmentService';
import LoadingSpinner from '@/components/utility-components/LoadingSpinner';
import ConfirmationDialogWithActivatorButton from '@/components/utility-components/dialogs/ConfirmationDialogWithActivatorButton';
import VehicleEditDialogListDetails from '@/components/vehicles/VehicleEditDialogListDetails';
import ConfirmationDialogWithDropDownAndActivator from '@/components/utility-components/dialogs/ConfirmationDialogWithDropDownAndActivator';
import ConfirmationDialogWithDropDown from '@/components/utility-components/dialogs/ConfirmationDialogWithDropDown';

export default {
  name: 'VehicleEditDialog',
  mixins: [SubscribeToStore('subscribeCallback')],
  components: {
    VehicleEditDialogListDetails,
    LoadingSpinner,
    LinkAssetInfo,
    ErrorBucket,
    VehicleInfoDialogue,
    DataGridDrawer,
    OTAActionItem,
    ConfirmationDialog,
    ConfirmationDialogWithDropDown,
    ConfirmationDialogWithDropDownAndActivator,
    ConfirmationDialogWithActivatorButton,
    ErrorDialog,
    VehicleStatusIcon,
    ProcessingDialog,
  },
  async mounted() {
    this.didMount = true;
  },
  props: {
    vehicleId: String,
    showDialog: Boolean,
    productFamilies: Array,
    uniqueId: String,
    equipmentTypes: Array,
    activeElement: Object,
  },
  data() {
    return {
      error: {},
      dialog: false,
      valid: true,
      capabilityCheckComplete: false,
      vehicleEditDetails: {
        vehicleId: '',
        name: '',
        vin: '',
        model: '',
        make: '',
        year: null,
        fuelType: '',
        licensePlate: '',
        formattedLicensePlateExpirationDate: '',
        licensePlateState: '',
      },
      vehicleDetails: {
        name: '',
        vin: '',
        model: '',
        make: '',
        year: null,
        fuelType: '',
        products: [],
        segments: [],
        aggregateDeletionCapabilityStatus: 'NONE',
        otaBlockStatus: null,
      },
      showErrorDialog: false,
      loading: true,
      loadingConsents: false,
      loadingLinkedAssets: false,
      showProcessing: false,
      goodVehicles: [],
      conflictingVehicles: [],
      confirmWhenConflict: false,
      subscribe: false,
      markForDeletion: false,
      productsInProductFamilies: [],
      capableProducts: [],
      processingMessage: '',
      confirmationDialog: {
        confirmationTitle: '',
        confirmationMessage: '',
        acceptanceText: '',
        cancelText: '',
        displayConfirm: false,
      },
      consentedVehicles: {},
      AccountConstants,
      selectedProductFamilyId: null,
      linkedAssets: [],
    };
  },
  methods: {
    dashify,
    subscribeCallback(mutation) {
      if (this.dialog && mutation.type === 'ota/PERFORM_BLOCK_OTA') {
        this.blockVehicles(
          this.blockUnblockPayload.vehicles,
          this.blockUnblockPayload.displayName
        );
      }

      if (this.dialog && mutation.type === 'ota/PERFORM_UNBLOCK_OTA') {
        this.unblockVehicles(
          this.blockUnblockPayload.vehicles,
          this.blockUnblockPayload.displayName
        );
      }
      if (this.dialog && mutation.type === 'equipment/REFRESH_EQUIPMENT') {
        this.refresh();
      }
    },
    displayExpirationDate(
      enrollmentDateString,
      subscriptionServiceActivationDateString,
      complimentaryBevTrialDays
    ) {
      let subscriptionServiceActivationDate = new Date(
        subscriptionServiceActivationDateString
      );
      if (subscriptionServiceActivationDate >= new Date()) {
        let complimentaryBevTrialThreshold = new Date(enrollmentDateString);
        complimentaryBevTrialThreshold.setDate(
          complimentaryBevTrialThreshold.getDate() +
            parseInt(complimentaryBevTrialDays)
        );
        return (
          subscriptionServiceActivationDate > complimentaryBevTrialThreshold
        );
      } else {
        return false;
      }
    },
    handleError(error) {
      this.error = ErrorMessageService.getErrorMessage(error);
      this.showErrorDialog = true;
    },
    processEnrollmentStatusResponse(res) {
      let productResponses = res.data;
      for (let productResponse of productResponses) {
        if (productResponse !== undefined && productResponse.capable) {
          let product = this.productsInProductFamilies.filter(
            (p) => p.id === productResponse.productSku
          )[0];
          this.capableProducts.push(product);
        }
      }

      this.capableProducts.sort((a, b) => {
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
          return 1;
        }
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
          return -1;
        }
        return 0;
      });
      this.capabilityCheckComplete = true;
    },
    runShowDialog() {
      this.goodVehicles = [];
      this.conflictingVehicles = [];
      this.confirmWhenConflict = false;
      this.subscribe = false;
      this.markForDeletion = false;
      this.productsInProductFamilies = [];
      this.capableProducts = [];
      this.vehicleDetails.products = [];
      if (!this.showDialog) {
        this.dialog = false;
        return;
      }

      this.refresh()
        .then(() => {
          this.getProductFamilies()
            .then(() => {
              this.capabilityCheckComplete = false;
              let productIds = this.productsInProductFamilies.map(
                (product) => product.id
              );
              VehicleService.getEnrollmentStatuses(productIds, [this.vehicleId])
                .then((res) => {
                  this.processEnrollmentStatusResponse(res);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialog = this.showDialog;
    },
    editVehicle() {
      VehicleService.editVehicle(this.vehicleEditDetails)
        .then(() => {
          let vinName = this.vehicleEditDetails.name
            ? this.vehicleEditDetails.name
            : this.vehicleEditDetails.vin;
          let message = this.$t('vehicle.editVehicle.snackBar.successMessage', {
            vinName: vinName,
          });
          this.$emit('vehicle-modified', message);
          this.$parent.$emit('close-drawer');
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    bulkDeconsentAndDisable() {
      let vehicleIds = [this.vehicleDetails.vehicleId];
      let promiseArray = Object.values(this.selectedServiceProviders).map(
        (serviceProvider) =>
          this.getDeconsentVehiclesPromise(serviceProvider, vehicleIds)
      );
      return Promise.all(promiseArray)
        .then((values) =>
          values.find((value) => value.status === 'rejected')
            ? Promise.reject(values)
            : values
        )
        .then(() => this.disableVehicle())
        .catch((error) => {
          console.log(error);
          this.addErrorNotification(
            this.$t(
              'vehicle.toolbar.deconsentAndDecommission.snackBarMessage.decommission.endError'
            )
          );
        });
    },
    disableVehicle() {
      VehicleService.disableVehicle(this.vehicleId)
        .then(() => {
          let vinName = this.vehicleEditDetails.name
            ? this.vehicleEditDetails.name
            : this.vehicleEditDetails.vin;
          let message = this.$t(
            'vehicle.editVehicle.snackBar.disableMessageDecommission',
            { vinName: vinName }
          );
          this.removeSelected({ data: [this.vehicleId] });
          this.$emit('vehicle-modified', message);
          this.$parent.$emit('close-drawer');
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getDeconsentVehiclesPromise(serviceProvider, vehicleIds) {
      return ConsentService.bulkDeconsentVehicle({
        vehicleIds,
        consumerId: serviceProvider.id,
      })
        .then((response) => {
          let message = this.$tc(
            'vehicle.toolbar.deconsentAndDecommission.snackBarMessage.deconsent.success',
            response.data,
            {
              count: response.data,
              consumerName: serviceProvider.name,
              vinName:
                this.vehicleDetails.name === ''
                  ? this.vehicleDetails.vin
                  : this.vehicleDetails.name,
            }
          );
          this.addSuccessNotification(message);
          return { status: 'resolved', data: response };
        })
        .catch((error) => {
          this.addErrorNotification(
            this.$t(
              'vehicle.toolbar.deconsentAndDecommission.snackBarMessage.deconsent.error',
              { consumerName: serviceProvider.name }
            )
          );
          console.log(error);
          return { status: 'rejected', data: error };
        });
    },
    hideDialog() {
      this.dialog = false;
      this.$parent.$emit('close-drawer');
    },
    hideDialogOnClick(payload) {
      if (this.activeElement) {
        this.activeElement.focus();
      }
      if (payload === undefined || !payload) {
        this.hideDialog();
      }
    },
    refresh() {
      this.loading = true;
      return VehicleService.getVehicle(this.vehicleId)
        .then((response) => {
          this.vehicleDetails = response.data;
          this.vehicleEditDetails = cloneDeep(this.vehicleDetails);
          this.vehicleEditDetails.fuelType = this.vehicleEditDetails.fuelType
            ? this.$t('vehicle.fuelTypes.' + this.vehicleEditDetails.fuelType)
            : this.vehicleEditDetails.fuelType;
          this.getConsents();
          if (this.canLinkEquipment) {
            this.getLinkedEquipment();
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLinkedEquipment() {
      this.loadingLinkedAssets = true;
      return EquipmentService.getLinkedAssetsByEntityId(this.vehicleId)
        .then((response) => {
          this.linkedAssets = response.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.loadingLinkedAssets = false;
        });
    },
    setConfirmationDialogMessage(status) {
      if (status === 'PROCESSING') {
        this.confirmationDialog.confirmationTitle = this.$t(
          'vehicle.editVehicle.unableToDecommissionConfirmationTitle'
        );
        this.confirmationDialog.confirmationMessage = this.$t(
          'vehicle.editVehicle.unableToDecommissionConfirmationMessage'
        );
        this.confirmationDialog.cancelText = this.$t('confirmationDialog.exit');
        this.confirmationDialog.displayConfirm = false;
      } else if (status === 'SUBSCRIBED') {
        this.confirmationDialog.confirmationTitle = this.$t(
          'vehicle.editVehicle.decommissionAndUnsubscribeConfirmationTitle'
        );
        this.confirmationDialog.confirmationMessage = this.$t(
          'vehicle.editVehicle.decommissionAndUnsubscribeConfirmationMessage'
        );
        this.confirmationDialog.acceptanceText = this.$t(
          'vehicle.editVehicle.decommissionAndUnsubscribe'
        );
        this.confirmationDialog.cancelText = this.$t(
          'confirmationDialog.cancel'
        );
        this.confirmationDialog.displayConfirm = true;
      } else if (this.consentedVehicles.length > 0) {
        this.confirmationDialog.confirmationTitle = this.$t(
          'vehicle.toolbar.deconsentAndDecommission.title'
        );
        this.confirmationDialog.confirmationMessage = this.$t(
          'vehicle.toolbar.deconsentAndDecommission.message',
          {
            nVehiclesIsare: this.$tc(
              'vehicle.nVehiclesWithAreOrIs',
              this.consentedVehicles.length,
              { numberOfVehicles: this.consentedVehicles.length }
            ),
            nVehicles: this.$tc(
              'vehicle.nVehicles',
              this.consentedVehicles.length,
              { numberOfVehicles: this.consentedVehicles.length }
            ),
          }
        );
        this.confirmationDialog.acceptanceText = this.$t(
          'vehicle.toolbar.deconsentAndDecommission.acceptanceText'
        );
        this.confirmationDialog.cancelText = this.$t(
          'confirmationDialog.cancel'
        );
        this.confirmationDialog.displayConfirm = true;
      } else {
        this.confirmationDialog.confirmationTitle = this.$t(
          'vehicle.editVehicle.decommissionConfirmationTitle'
        );
        this.confirmationDialog.confirmationMessage = this.$t(
          'vehicle.editVehicle.decommissionConfirmationMessage',
          { dayCount: '30' }
        );
        this.confirmationDialog.acceptanceText = this.$t(
          'vehicle.editVehicle.decommission'
        );
        this.confirmationDialog.cancelText = this.$t(
          'confirmationDialog.cancel'
        );
        this.confirmationDialog.displayConfirm = true;
      }
    },
    getProductFamilies() {
      this.productsInProductFamilies = this.getProductsFromProductFamilies(
        this.productFamilies
      );
      return Promise.resolve(this.productsInProductFamilies);
    },
    getProductsFromProductFamilies(productFamilies) {
      let resultArray = [];
      productFamilies.forEach((productFamily) => {
        productFamily.products.forEach((product) => {
          resultArray.push({
            name:
              this.$tf(
                'productFamily.' + productFamily.id,
                productFamily.name
              ) +
              ' - ' +
              product.name,
            id: product.sku,
            productFamilyId: productFamily.id,
          });
        });
      });
      return resultArray;
    },
    formatDate(date) {
      moment.locale(this.$i18n?.locale);
      if (!date) return '';
      return moment(new Date(date)).format('DD-MMM-YYYY');
    },
    subscribeVehiclesToProduct(product, vehicles) {
      VehicleService.subscribeVehiclesToProduct(
        product.id,
        vehicles.map((a) => a.vehicle.vehicleId)
      )
        .then(() => {
          let message = this.$tc(
            'vehicle.toolbar.subscribeProducts.snackBarMessage.success',
            vehicles.length,
            {
              count: vehicles.length,
              productName: product.name,
              vinName: vehicles[0].vehicle.displayName,
            }
          );
          this.addSuccessNotification(message);
        })
        .catch((err) => {
          console.log(err);
          let message = this.$tc(
            'vehicle.toolbar.subscribeProducts.snackBarMessage.error',
            1,
            {
              productName: product.name,
              vinName: vehicles[0].vehicle.displayName,
            }
          );
          if (
            err.response.data.message !== undefined &&
            err.response.data.message != null
          ) {
            if (
              err.response.data.message.includes('code=1007') ||
              err.response.data.message.includes('code=1009')
            ) {
              message = this.$tc(
                'vehicle.toolbar.subscribeProducts.snackBarMessage.errorProductDependencyConstraint',
                1,
                {
                  productName: product.name,
                  vinName: vehicles[0].vehicle.displayName,
                }
              );
            } else if (err.response.data.message.includes('code=1008')) {
              message = this.$tc(
                'vehicle.toolbar.subscribeProducts.snackBarMessage.errorExistingProductsAndProductDependencyConstraint',
                1,
                {
                  productName: product.name,
                  vinName: vehicles[0].vehicle.displayName,
                }
              );
            }
          }
          this.addErrorNotification(message);
        })
        .finally(() => {
          this.showProcessing = false;
          this.refresh();
        });
    },
    attemptUnsubscribe(product) {
      this.processingMessage = this.$tc(
        'vehicle.processingBar.unsubscribeMessage',
        1,
        { count: 1 }
      );
      this.showProcessing = true;
      VehicleService.attemptUnsubscribe(product, new Array(this.vehicleId))
        .then((res) => {
          if (res && res.conflict) {
            this.goodVehicles = res.goodVehicles;
            this.subscribe = false;
            this.conflictingVehicles = res.badVehicles;
            let message = this.$tc(
              'vehicle.toolbar.unsubscribeProducts.snackBarMessage.error',
              this.conflictingVehicles.length,
              {
                count: this.conflictingVehicles.length,
                productName: product.name,
                vinName: this.conflictingVehicles[0].vehicle.displayName,
              }
            );
            this.addErrorNotification(message);
          } else {
            let message = this.$tc(
              'vehicle.toolbar.unsubscribeProducts.snackBarMessage.success',
              1,
              {
                count: 1,
                productName: product.name,
                vinName: this.vehicleDetails.name,
              }
            );
            this.addSuccessNotification(message);
          }
          this.showProcessing = false;
          this.refresh();
        })
        .catch((err) => {
          console.log(err);
          let message = this.$tc(
            'vehicle.toolbar.unsubscribeProducts.snackBarMessage.error',
            1,
            {
              productName: product.name,
              vinName: this.vehicleDetails.name,
            }
          );
          this.addErrorNotification(message);
          this.showProcessing = false;
          this.refresh();
        });
    },
    attemptSubscribe(productSku) {
      this.processingMessage = this.$tc(
        'vehicle.processingBar.subscribeMessage',
        1,
        { count: 1 }
      );
      this.showProcessing = true;
      VehicleService.getEnrollmentStatus(
        productSku.id,
        new Array(this.vehicleId)
      )
        .then((res) => {
          let vehicles = res.data;
          let badVehicles = vehicles.filter((value) => {
            return value.capable === false;
          });
          let goodVehicles = vehicles.filter((value) => {
            return value.capable;
          });
          if (goodVehicles.length > 0 && badVehicles.length === 0) {
            this.subscribeVehiclesToProduct(productSku, goodVehicles);
          } else {
            this.goodVehicles = goodVehicles;
            this.subscribe = true;
            this.conflictingVehicles = badVehicles;
            this.confirmWhenConflict = true;
            this.showProcessing = false;
          }
        })
        .catch((error) => {
          this.showProcessing = false;
          console.log(error);
        });
    },
    reshowProductDialog() {
      if (this.subscribe) {
        this.$refs['subscribeSingleProduct'].dialog = true;
      } else if (this.markForDeletion) {
        this.$refs.disableVehicleConfirmation.dialog = true;
      } else {
        this.$refs[
          'unsubscribeSingleProduct-' + this.activeProductIndex
        ][0].dialog = true;
      }
    },
    cancelUnsubscribeDialog(productIndex) {
      if (
        this.$refs['unsubscribeSingleProductButton-' + productIndex] &&
        this.$refs['unsubscribeSingleProductButton-' + productIndex] instanceof
          Array &&
        this.$refs['unsubscribeSingleProductButton-' + productIndex].length > 0
      ) {
        this.$refs[
          'unsubscribeSingleProductButton-' + productIndex
        ][0].$el.focus();
      }
    },
    conflictTitle() {
      if (this.subscribe) {
        return this.$t('vehicle.toolbar.unableToSubscribeProducts.title');
      } else if (this.markForDeletion) {
        return this.$t('vehicle.toolbar.deconsentAndDecommission.title');
      }
      return this.$t('vehicle.toolbar.unableToUnsubscribeProducts.title');
    },
    conflictMessage() {
      if (this.markForDeletion) {
        return this.$t('vehicle.toolbar.deconsentAndDecommission.message', {
          nVehiclesIsare: this.$tc(
            'vehicle.nVehiclesWithAreOrIs',
            this.consentedVehicles.length,
            { numberOfVehicles: this.consentedVehicles.length }
          ),
          nVehicles: this.$tc(
            'vehicle.nVehicles',
            this.consentedVehicles.length,
            { numberOfVehicles: this.consentedVehicles.length }
          ),
        });
      }
      if (this.goodVehicles.length > 0) {
        if (this.subscribe) {
          return this.$t('vehicle.toolbar.unableToSubscribeProducts.message', {
            nBadVehicles: this.$tc(
              'vehicle.nVehiclesWithAreOrIs',
              this.conflictingVehicles.length,
              { numberOfVehicles: this.conflictingVehicles.length }
            ),
            nGoodVehicles: this.$tc(
              'vehicle.numberOfVehicles',
              this.goodVehicles.length,
              { numberOfVehicles: this.goodVehicles.length }
            ),
          });
        }
        return this.$t('vehicle.toolbar.unableToUnsubscribeProducts.message', {
          nBadVehicles: this.$tc(
            'vehicle.nVehiclesWithAreOrIs',
            this.conflictingVehicles.length,
            { numberOfVehicles: this.conflictingVehicles.length }
          ),
          nGoodVehicles: this.$tc(
            'vehicle.numberOfVehicles',
            this.goodVehicles.length,
            { numberOfVehicles: this.goodVehicles.length }
          ),
        });
      }

      return this.$t(
        `vehicle.toolbar.unableToSubscribeProducts.${
          this.conflictingVehicles.length > 1
            ? 'noVehiclesMessage'
            : 'noVehiclesMessageSingular'
        }`,
        {
          count: this.conflictingVehicles.length,
        }
      );
    },
    conflictAcceptanceText() {
      if (this.markForDeletion) {
        return this.$t(
          'vehicle.toolbar.deconsentAndDecommission.acceptanceText'
        );
      }
      if (this.goodVehicles.length > 0) {
        if (this.subscribe) {
          return this.$t(
            'vehicle.toolbar.unableToSubscribeProducts.acceptanceText'
          );
        }
        return this.$t(
          'vehicle.toolbar.unableToUnsubscribeProducts.acceptanceText'
        );
      } else {
        return this.$t('vehicle.toolbar.unableToSubscribeProducts.exit');
      }
    },
    retryEnrollmentAction(product) {
      this.showProcessing = true;
      if (
        product.enrollmentStatus ===
        AccountConstants.ENROLLMENT_STATUS_FAILED_TO_UNSUBSCRIBE
      ) {
        this.processingMessage = this.$tc(
          'vehicle.processingBar.unsubscribeMessage',
          1,
          { count: 1 }
        );
        this.unenrollProduct(product, [this.vehicleId]);
      } else if (
        product.enrollmentStatus ===
        AccountConstants.ENROLLMENT_STATUS_FAILED_TO_SUBSCRIBE
      ) {
        this.processingMessage = this.$tc(
          'vehicle.processingBar.subscribeMessage',
          1,
          { count: 1 }
        );
        this.subscribeVehiclesToProduct(
          {
            id: product.productSku,
            name: product.name,
          },
          [
            {
              vehicle: {
                vehicleId: this.vehicleId,
                displayName:
                  this.vehicleDetails.name === ''
                    ? this.vehicleDetails.vin
                    : this.vehicleDetails.name,
              },
            },
          ]
        );
      } else {
        this.showProcessing = false;
      }
    },
    cancelEnrollmentFailedStatus(product, vin) {
      VehicleService.clearEnrollmentFailedStatus(product.productSku, vin)
        .then(() => {
          let message = this.$tc(
            'vehicle.toolbar.cancelFailedEnrollment.snackBarMessage.success',
            1,
            {
              count: 1,
              productName: product.name,
              vin: vin,
            }
          );
          this.showProcessing = false;
          this.$emit('vehicle-modified', message);
          this.refresh();
        })
        .catch((err) => {
          console.log(err);
          let message = this.$tc(
            'vehicle.toolbar.cancelFailedEnrollment.snackBarMessage.error',
            1,
            {
              productName: product.name,
              vin: vin,
            }
          );
          this.addErrorNotification(message);
          this.showProcessing = false;
          this.refresh();
        });
    },
    unenrollProduct(product, vehicleIds) {
      VehicleService.unenrollVehicleProduct(product.productSku, vehicleIds)
        .then(() => {
          let message = this.$tc(
            'vehicle.toolbar.unsubscribeProducts.snackBarMessage.success',
            1,
            {
              count: 1,
              productName: product.name,
              vinName: this.vehicleDetails.name,
            }
          );
          this.addSuccessNotification(message);
          this.showProcessing = false;
          this.refresh();
        })
        .catch((err) => {
          console.log(err);
          let message = this.$tc(
            'vehicle.toolbar.unsubscribeProducts.snackBarMessage.error',
            1,
            {
              productName: product.name,
              vinName: this.vehicleDetails.name,
            }
          );
          this.addErrorNotification(message);
          this.showProcessing = false;
          this.refresh();
        });
    },
    getConsents() {
      this.loadingConsents = true;
      ConsentService.getConsents([this.vehicleDetails.vehicleId])
        .then((consentResponse) => {
          this.consentedVehicles = cloneDeep(consentResponse.data);
          this.setConfirmationDialogMessage(
            this.vehicleDetails.aggregateDeletionCapabilityStatus
          );
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.loadingConsents = false;
        });
    },
    determineDisableConflict() {
      this.markForDeletion = true;
      if (
        this.consentedVehicles.length > 0 &&
        (this.vehicleDetails.aggregateDeletionCapabilityStatus ===
          'PROCESSING' ||
          this.vehicleDetails.aggregateDeletionCapabilityStatus ===
            'SUBSCRIBED')
      ) {
        this.confirmWhenConflict = true;
      } else {
        this.bulkDeconsentAndDisable();
      }
    },
    conflictConfirmAction() {
      this.confirmWhenConflict = false;
      if (this.markForDeletion) {
        this.bulkDeconsentAndDisable();
      } else {
        this.refresh();
      }
    },
    shouldDisplayEnrollmentMessage(messageCode) {
      return AccountConstants.DISPLAYABLE_ENROLLMENT_MESSAGE_CODES.includes(
        messageCode
      );
    },
    blockVehicles(vehicles, displayName) {
      this.showProcessing = true;
      this.processingMessage = this.$tc('ota.processingBar.blockMessage', 1, {
        numberOfVehicles: 1,
      });
      OTAService.blockOTAVehicles(vehicles)
        .then(() => {
          let message = this.$tc('ota.snackBar.block.successMessage', 1, {
            displayName: displayName,
            numberOfVehicles: 1,
          });
          this.addSuccessNotification(message);
          this.refresh();
        })
        .catch((error) => {
          this.showProcessing = false;
          let message = this.$tc('ota.snackBar.block.failureMessage', 1, {
            numberOfVehicles: 1,
            displayName: displayName,
          });
          this.addErrorNotification(message);
          console.log(error);
        })
        .finally(() => {
          this.showProcessing = false;
        });
    },
    unblockVehicles(vehicles, displayName) {
      this.showProcessing = true;
      this.processingMessage = this.$tc('ota.processingBar.unblockMessage', 1, {
        numberOfVehicles: 1,
      });
      OTAService.unblockOTAVehicles(vehicles)
        .then(() => {
          let message = this.$tc('ota.snackBar.unblock.successMessage', 1, {
            displayName: displayName,
            numberOfVehicles: 1,
          });
          this.addSuccessNotification(message);
          this.refresh();
        })
        .catch((error) => {
          this.showProcessing = false;
          let message = this.$tc('ota.snackBar.unblock.failureMessage', 1, {
            numberOfVehicles: 1,
            displayName: displayName,
          });
          this.addErrorNotification(message);
          console.log(error);
        })
        .finally(() => {
          this.showProcessing = false;
        });
    },
    shouldDisableOTAToggle(status) {
      return (
        status === AccountConstants.ENROLLMENT_STATUS_PROCESSING_UNSUBSCRIBE
      );
    },
    shouldShowOTAToggle(sku, otaBlockStatus, enrollmentStatus) {
      return (
        sku === AccountConstants.OTA_BLOCK_SKU &&
        otaBlockStatus !== null &&
        enrollmentStatus !==
          AccountConstants.ENROLLMENT_STATUS_PROCESSING_SUBSCRIBE &&
        enrollmentStatus !==
          AccountConstants.ENROLLMENT_STATUS_FAILED_TO_SUBSCRIBE
      );
    },
    displayErrorBucket(product) {
      return (
        product.errorBucket &&
        ['UNENROLLMENT_ALERT', 'ENROLLMENT_ALERT', 'ACTION'].includes(
          product.enrollmentStatus
        )
      );
    },
    valueChangedInDrawer(event) {
      this.vehicleEditDetails[event.key] = event.value;
    },
    ...mapActions('notification', [
      'addSuccessNotification',
      'addErrorNotification',
    ]),
    setSelectedProductFamilyId($event) {
      let product = this.productsInProductFamilies.find((p) => p.id === $event);
      this.selectedProductFamilyId =
        product !== null && product !== undefined
          ? product.productFamilyId
          : null;
    },
    ...mapActions('vehicle', ['removeSelected']),
  },
  computed: {
    ...mapState('configuration', ['showFailedEnrollmentCancelButton']),
    isModified() {
      return (
        this.vehicleEditDetails?.name !== this.vehicleDetails?.name ||
        this.vehicleEditDetails?.licensePlate !==
          this.vehicleDetails?.licensePlate ||
        this.vehicleEditDetails?.licensePlateState !==
          this.vehicleDetails?.licensePlateState ||
        this.vehicleEditDetails?.formattedLicensePlateExpirationDate !==
          this.vehicleDetails?.formattedLicensePlateExpirationDate
      );
    },
    selectedServiceProviders() {
      return Object.values(this.consentedVehicles).reduce(
        (accumulator, currentVehicle) => {
          currentVehicle.consentedConsumers.forEach((currentConsumer) => {
            accumulator[currentConsumer.id] = currentConsumer;
          });
          return accumulator;
        },
        {}
      );
    },
    subscribeProductsLabel() {
      if (this.capabilityCheckComplete) {
        return this.$t('vehicle.editVehicle.products.subscribe');
      }
      return this.$t('vehicle.editVehicle.products.checkingCapability');
    },
    productUnsubscribeMessage() {
      let defaultMessage = this.$t(
        'vehicle.toolbar.unsubscribeProducts.message',
        {
          nVehicles: this.$tc('vehicle.nVehicles', this.selectedLength, {
            numberOfVehicles: 1,
          }),
        }
      );
      let unlinkMessage = this.$tc(
        'vehicle.toolbar.unsubscribeProducts.unlinkMessage',
        1
      );
      return this.selectedProductFamilyId === this.fordTelematicsProductFamilyId
        ? defaultMessage + unlinkMessage
        : defaultMessage;
    },
    showVehicleListInDialog() {
      return (
        this.consentedVehicles.length > 0 &&
        this.vehicleDetails.aggregateDeletionCapabilityStatus !==
          'PROCESSING' &&
        this.vehicleDetails.aggregateDeletionCapabilityStatus !== 'SUBSCRIBED'
      );
    },
    ...mapGetters('auth', [
      'canViewGroups',
      'canViewMarkForDelete',
      'canLinkEquipment',
    ]),
    ...mapGetters('ota', ['blockUnblockPayload']),
    ...get('configuration', [
      'complimentaryBevTrialDays',
      'fordTelematicsProductFamilyId',
    ]),
  },
  watch: {
    showDialog() {
      this.runShowDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
[data-qa='edit-vehicle-form'] {
  display: flex;
  flex-direction: column;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot {
  border-style: none;
}

::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  width: 0;

  .v-label {
    left: 0;
    right: auto;
    position: absolute;
    color: black;
    font-weight: bold;
    font-size: 22px;
  }
}

::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
  width: 0;
}

::v-deep .v-label {
  left: 0;
  right: auto;
  position: absolute;
  color: black;
  font-weight: bold;
  font-size: 22px;
}

[data-qa='vehicle-cancel'] {
  text-decoration: underline;
}

[data-qa='vehicle-name'] {
  max-width: 200px;
  min-width: 50px;
  min-height: 20px;
}

::v-deep .v-small-dialog .v-menu__activator {
  height: 0;
  font-size: 16px;
}

.edit-label {
  * {
    display: inline;
  }

  .v-icon {
    vertical-align: middle;
    padding-bottom: 4px;
    padding-left: 0.5em;
  }

  padding-top: 4px;
}

.vehicle-edit-dialog {
  position: fixed;
}

.remove_design {
  padding: 0;
  height: 0;
  margin: 0;
}

::v-deep .consent-list-item {
  width: 50%;
  display: inline-block;
  box-sizing: content-box;

  &.text-right {
    text-align: right;
  }
}

.ota-switch ::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
}

.ota-switch ::v-deep .v-input--selection-controls .v-input__control {
  padding-left: 5px;

  .v-input__slot {
    max-height: 8px;

    .v-label {
      font-size: 12px;
      font-weight: normal;
      color: var(--v-primary-base);
    }
  }
}

.titleCase {
  text-transform: capitalize;
}
</style>
