<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-flex>
    <h2
      class="headline primary--text confirmation-header text-uppercase"
      data-qa="step-3-title"
    >
      {{ getConfirmationStepTitle }}
    </h2>
    <v-layout align-center justify-center row>
      <p
        class="confirm-text"
        data-qa="step-3-confirmation-text"
        v-html="getConfirmationStepText"
      >
      </p>
      <IconTooltip
        v-if="isComplimentary"
        :tooltip-message="$t('products.complimentaryProducts.toolTip.message')"
        data-qa-key="complimentary-tooltip"
      />
    </v-layout>

    <div v-if="shouldDisplayPriceConfirmation" class="mt-3 confirm-text">
      <div
        v-for="(priceGroup, index) in displayPriceGroupings"
        :key="index"
        data-qa="upgrade-product-pricing-content"
      >
        <b>
          {{
            $tc(priceGroup.vehicleCountDisplay, priceGroup.vehicleCount, {
              numberOfVehicles: priceGroup.vehicleCount,
            })
          }}
        </b>
        {{ $t('upgradeProductPopup.costDetails') }}
        <b> {{ cost(priceGroup.currency, priceGroup.price) }} </b>
        {{ $t('productUpgrade.includedPriceDisclosure') }}
      </div>
    </div>

    <div
      v-if="!isComplimentary"
      class="mt-3 confirm-text"
      data-qa="product-add-charge-description"
    >
      {{ getBillExplanationText }}
    </div>

    <v-layout
      v-if="displayTermsAndConditionsDownload"
      data-qa="terms-and-conditions-download"
      align-center
      justify-center
      row
    >
      <p class="my-3 confirm-text">
        {{ $t('basics.the') }}
      </p>
      <LatestTermsAndConditionsDownloadDialog
        :hyperlink-text="$t('termsAndConditions.fordPro.title')"
      />
      <p class="my-3 confirm-text">
        {{ $t('termsAndConditions.apply') }}
      </p>
    </v-layout>
    <v-layout text-center v-if="shouldDisplayPriceConfirmation">
      <v-checkbox
        color="primary"
        v-model="agreedToCostIncrease"
        data-qa="agree-to-payment-increase-checkbox"
        class="align-center justify-center"
        :label="
          $tc('productUpgrade.priceIncreaseConfirmation', 1, {
            price: totalPriceForUpgrade,
          })
        "
      />
    </v-layout>
    <v-flex v-if="isComplimentary">
      <i18n
        v-if="showReminder"
        class="reminder-text"
        data-qa="step-complimentary-reminder-text"
        path="products.complimentaryProducts.reminderMsg.message"
        tag="p"
      >
        <template v-slot:reminder>
          <strong data-qa="step-3-complimentary-1">
            {{ $t('products.complimentaryProducts.reminderMsg.reminder') }}
          </strong>
        </template>
        <template v-slot:removeProductName>
          <span data-qa="step-3-complimentary-remove-product-name">
            {{ $t('productFamily.' + fordTelematicsEssentialsProductFamilyId) }}
          </span>
        </template>
        <template v-slot:newProductName>
          <span data-qa="step-3-complimentary-new-product-name">
            {{ $t('productFamily.' + fordTelematicsProductFamilyId) }}
          </span>
        </template>
      </i18n>
      <br />
      <v-divider />
      <h3 class="complimentary-title" data-qa="step-3-complimentary-title">{{
        $t('products.complimentaryProducts.expectTitle')
      }}</h3>
      <v-layout justify-center row>
        <v-sheet class="complimentary-sheet">
          <v-layout align-center justify-center row>
            <v-icon class="sheet-vehicle-image">ic-vehicles</v-icon>
            <v-icon class="sheet-plug-image fa-plug" />
          </v-layout>
          <i18n
            class="complimentary-text-description"
            data-qa="step-3-complimentary-trial-message"
            path="products.complimentaryProducts.trialPeriodMsg.subscribedTo"
            tag="p"
          >
            <template v-slot:eligibleVehicles>
              <strong>{{
                $t(
                  'products.complimentaryProducts.trialPeriodMsg.eligibleVehicles'
                )
              }}</strong>
            </template>
            <template v-slot:complimentaryTrial>
              <strong>{{
                $t(
                  'products.complimentaryProducts.trialPeriodMsg.complimentaryTrial'
                )
              }}</strong>
            </template>
          </i18n>
        </v-sheet>
        <v-icon class="far-secondary fa-arrow-right fa-2x" />
        <v-sheet class="complimentary-sheet">
          <v-icon class="sheet-page-image">ic-billing</v-icon>
          <i18n
            class="complimentary-text-description"
            data-qa="step-3-complimentary-price-message"
            path="products.complimentaryProducts.priceMsg.subscribedTo"
            tag="p"
          >
            <template v-slot:allVehicles>
              <strong>{{
                $t('products.complimentaryProducts.priceMsg.allVehicles')
              }}</strong>
            </template>
            <template v-slot:pricedRate>
              <strong>{{
                $t('products.complimentaryProducts.priceMsg.pricedRate')
              }}</strong>
            </template>
          </i18n>
        </v-sheet>
        <v-icon class="far-secondary fa-arrow-right fa-2x" />
        <v-sheet class="complimentary-sheet">
          <v-icon class="sheet-recycle-image">fa-sync-alt</v-icon>
          <i18n
            class="complimentary-text-description"
            data-qa="step-3-complimentary-expire-message"
            path="products.complimentaryProducts.expireMsg.subscribedTo"
            tag="p"
          >
            <template v-slot:willExpire>
              <strong>{{
                $t('products.complimentaryProducts.expireMsg.willExpire')
              }}</strong>
            </template>
            <template v-slot:pricedRate>
              <strong>{{
                $t('products.complimentaryProducts.expireMsg.pricedRate')
              }}</strong>
            </template>
            <template v-slot:unenrolled>
              <strong>{{
                $t('products.complimentaryProducts.expireMsg.unenrolled')
              }}</strong>
            </template>
          </i18n>
        </v-sheet>
      </v-layout>
    </v-flex>
  </v-flex>
</template>

<script>
import IconTooltip from '@/components/IconTooltip';
import { get, sync } from 'vuex-pathify';
import coinify from 'coinify';
import { groupBy } from 'lodash';
import {
  upgradeableSkuToCurrency,
  totalVehicleUpgradeCount,
} from '@/productUpgradeUtils';
import LatestTermsAndConditionsDownloadDialog from '@/components/terms-and-conditions/LatestTermsAndConditionsDownloadDialog.vue';

export default {
  name: 'ProductAddUpgradeConfirmation',
  props: {
    isUpgrade: {
      type: Boolean,
      default: false,
    },
    isComplimentary: {
      type: Boolean,
      default: false,
    },
    showReminder: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array,
      default: () => [],
    },
    displayTermsAndConditionsDownload: {
      type: Boolean,
      default: false,
    },
    oldProductFamilyName: String,
  },
  components: {
    LatestTermsAndConditionsDownloadDialog,
    IconTooltip,
  },
  methods: {
    cost(currency, price) {
      return coinify.symbol(currency) + price;
    },
    displayByVehicleType(priceGroup) {
      if (priceGroup.skus.length > 1) {
        return 'vehicle.numberOfVehicles';
      }
      if (this.iceSkus.includes(priceGroup.skus[0])) {
        return 'vehicle.numberOfIceVehicles';
      }
      if (this.bevSkus.includes(priceGroup.skus[0])) {
        return 'vehicle.numberOfBevVehicles';
      }
      return 'vehicle.numberOfVehicles';
    },
  },
  computed: {
    ...get('configuration', [
      'fordTelematicsEssentialsProductFamilyId',
      'fordTelematicsProductFamilyId',
      'iceSkus',
      'bevSkus',
    ]),
    ...get('productUpgrade', ['skusToUpgrade']),
    ...sync('productUpgrade', ['agreedToCostIncrease']),
    displayPriceGroupings() {
      let onlyOnePrice = this.getPriceGroupings.length === 1;
      return this.getPriceGroupings.map((pricing) => {
        return {
          ...pricing,
          vehicleCountDisplay: onlyOnePrice
            ? 'vehicle.numberOfVehicles'
            : this.displayByVehicleType(pricing),
        };
      });
    },
    getPriceGroupings() {
      let pricingMap = groupBy(this.skusToUpgrade, (sku) => sku.price);
      return Object.entries(pricingMap)
        .reduce((acc, [key, value]) => {
          let vehicleCount = totalVehicleUpgradeCount(value);
          if (vehicleCount > 0) {
            return [
              ...acc,
              {
                price: key,
                vehicleCount,
                skus: value.map((obj) => obj.productSku),
                currency: upgradeableSkuToCurrency(value[0]),
              },
            ];
          }
          return acc;
        }, [])
        .sort((a, b) => (a.vehicleCount > b.vehicleCount ? -1 : 1));
    },
    shouldDisplayPriceConfirmation() {
      return this.isUpgrade && totalVehicleUpgradeCount(this.skusToUpgrade) > 0;
    },
    totalPriceForUpgrade() {
      let total = this.skusToUpgrade.reduce(
        (acc, sku) => acc + sku.price * sku.vehicleCount,
        0
      );
      return upgradeableSkuToCurrency(this.skusToUpgrade[0]) + total;
    },
    getConfirmationStepTitle() {
      if (this.isUpgrade) {
        return this.$t('productUpgradeStepper.stepThreeTitle');
      } else if (this.isComplimentary) {
        return this.$t('products.complimentaryProducts.stepThreeTitle');
      } else {
        return this.$t('productUpgradeStepper.stepThreeTitleAdd');
      }
    },
    getBillExplanationText() {
      if (this.isUpgrade) {
        return this.$t('productUpgradeStepper.productUpgrade.billExplanation');
      } else {
        return this.$t('productUpgradeStepper.productAdd.billExplanation');
      }
    },
    getConfirmationStepText() {
      const productFamilyName = this.products[0].productFamilyName;
      if (this.isUpgrade) {
        return this.$t(
          'productUpgradeStepper.productUpgrade.confirmExplanation',
          {
            oldProductFamily: this.$tf(
              'productFamily.' + this.oldProductFamilyName,
              this.oldProductFamilyName
            ),
            newProductFamily: this.$tf(
              'productFamily.' + productFamilyName,
              productFamilyName
            ),
          }
        );
      } else if (this.isComplimentary) {
        return this.$tf('products.complimentaryProducts.confirmExplanation');
      } else {
        return this.$tc(
          'productUpgradeStepper.productAdd.confirmExplanation',
          this.products.length,
          { count: this.products.length, productFamilyName: productFamilyName }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation-header {
  text-align: center;
  padding-bottom: 2%;
  font-size: 22px !important;
  color: #292e35 !important;
}

.confirm-text {
  text-align: center;
  font-size: 16px;
  padding-right: 5px;
  margin-bottom: 0;
}

.far-secondary {
  font-family: 'Font Awesome 5 Pro', serif;
  font-weight: 600;
  text-align: center;
  color: var(--v-secondary-base) !important;
}

.sheet-vehicle-image {
  color: var(--v-primary-base) !important;
  font-size: 58px;
}

.sheet-page-image {
  color: var(--v-primary-base) !important;
  margin-top: 6px;
  font-size: 48px;
}

.sheet-plug-image {
  font-family: 'Font Awesome 5 Pro', serif;
  padding-left: 5px;
  font-size: 27px;
  font-weight: bold;
  color: var(--v-secondary-base) !important;
}

.sheet-recycle-image {
  color: var(--v-primary-base) !important;
  font-size: 41px;
  margin: 10px 0 2px 0;
}

.complimentary-title {
  text-align: center;
  padding: 24px 0 20px 0;
  font-size: 19px;
  color: var(--v-primary-base) !important;
}

.complimentary-sheet {
  padding: 0 24px;
  width: 400px;
  text-align: center;
}

.complimentary-text-description {
  padding-top: 4px;
  color: var(--v-primary-base) !important;
}

.reminder-text {
  padding: 15px 0 30px 0;
  margin-bottom: 0;
}
</style>
