<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    :max-width="maxWidth"
    v-model="dialog"
    scrollable
    :persistent="persistent"
    @keydown.esc="cancel"
  >
    <!-- eslint-disable-next-line vue/no-unused-vars -->
    <template v-slot:activator="data">
      <block-button
        @click="openDialog"
        :data-qa="activator.dataQaKey"
        :color="activator.color"
        ref="activatorButton"
      >
        {{ activator.text }}
      </block-button>
    </template>
    <ErrorCard v-if="displayError" :error="error" @close="cancel" />
    <v-card v-else :data-qa="`confirmation-dialog-${dataQaKey}`">
      <v-card-title>
        <h1 class="title primary--text">
          <slot name="title"></slot>
        </h1>
      </v-card-title>
      <v-card-text v-if="$slots.message" style="padding-bottom: 8px">
        <p class="body-1" style="margin: auto; font-weight: 500">
          <slot name="message"></slot>
        </p>
      </v-card-text>
      <v-card-text
        style="overflow-y: hidden; padding-top: 0; padding-bottom: 8px"
      >
        <slot name="link"></slot>
      </v-card-text>
      <v-divider v-if="!hideContent && displayDivider"></v-divider>
      <v-card-text
        v-if="!hideContent"
        :id="`confirmation-dialog-content-${dataQaKey}`"
        data-qa="confirmation-dialog-content"
        :style="{ height: height }"
      >
        <slot name="content"></slot>
      </v-card-text>
      <v-card-text
        v-if="$slots.footer"
        :id="`confirmation-dialog-footer-${dataQaKey}`"
        class="body-1"
      >
        <slot name="footer"></slot>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <cancel-button
          ref="cancelBtn"
          data-qa="confirmation-dialog-cancel"
          :color="displayConfirm ? '' : 'primary'"
          @click="cancel"
          @keydown.tab.prevent="trapFocus"
        >
          <slot name="cancel-text"></slot>
        </cancel-button>
        <slot name="confirmation-section">
          <confirm-button
            v-if="displayConfirm"
            ref="confirmBtn"
            data-qa="confirmation-dialog-confirm"
            @click="
              $emit('confirm');
              dialog = false;
            "
            @keydown.tab.prevent="trapFocus"
            :disabled="confirmDisabled"
          >
            <slot name="confirm-text"></slot>
          </confirm-button>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dashify from 'dashify';
import ErrorCard from '@/components/utility-components/dialogs/ErrorCard';
import CancelButton from '@/components/utility-components/dialogs/CancelButton';
import ConfirmButton from '@/components/utility-components/dialogs/ConfirmButton';
import BlockButton from '@/components/utility-components/BlockButton';

export default {
  name: 'ConfirmationDialogWithActivatorButton',
  components: { BlockButton, ConfirmButton, CancelButton, ErrorCard },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    dialog: {
      get: function () {
        return this.showDialog || this.enableDialog;
      },
      set: function (v) {
        this.showDialog = v;
        if (v === false) {
          this.$emit('setEnableDialogFalse');
        } else {
          this.$emit('setEnableDialogTrue');
        }
      },
    },
  },
  props: {
    dataQaKey: String,
    error: {
      title: String,
      description: String,
      action: String,
    },
    activator: {
      text: String,
      dataQaKey: String,
      color: String,
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    displayDivider: {
      type: Boolean,
      default: true,
    },
    displayConfirm: {
      type: Boolean,
      default: true,
    },
    enableDialog: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '200px',
    },
    maxWidth: {
      type: String,
      default: '480px',
    },
    hideContent: {
      type: Boolean,
      default: false,
      required: false,
    },
    displayError: {
      type: Boolean,
      default: false,
      required: false,
    },
    cancelAndContinue: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dashify,
    openDialog() {
      if (!this.showDialog) {
        this.showDialog = true;
      }
    },
    cancel() {
      if (this.cancelAndContinue) {
        this.$emit('cancelAndContinue');
      } else {
        this.$emit('cancel');
      }
      this.revertFocus();
      this.dialog = false;
    },
    revertFocus() {
      if (this.$refs.activatorButton) {
        setTimeout(() => {
          this.$refs.activatorButton.$el.focus();
        }, 100);
      }
    },
    trapFocus(event) {
      if (event.target === this.$refs.cancelBtn.$el) {
        this.$refs.confirmBtn.$el.focus();
      } else {
        this.$refs.cancelBtn.$el.focus();
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  max-width: 480px;
}

.v-dialog__container {
  display: none !important;
}
</style>
