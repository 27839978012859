<!--suppress CssInvalidPseudoSelector -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div id="global-topbar" class="global-topbar">
    <v-toolbar
      app
      color="white"
      data-qa="global-topbar"
      clipped-left
      height="50px"
    >
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        min-width="240px"
        offset-y
        nudge-bottom="7"
        content-class="app-dropdown"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            data-qa="global-topbar-waffle"
            :aria-label="$t('globalTopbar.waffle.label')"
            class="list-item-icon waffle-icon"
            :class="isRegistrationForm ? 'pointer-events-none' : ''"
            v-on="on"
          >
            <v-icon
              data-qa="global-topbar-waffle-icon"
              class="app-header-text"
              size="22pt"
              >ic-waffle</v-icon
            >
          </v-btn>
        </template>
        <v-card data-qa="app-dropdown" class="pb-2">
          <v-list>
            <v-list-tile class="px-2">
              <v-list-tile-title>
                <h3
                  class="body-2 app-dropdown-title font-weight-bold primary--text"
                  >{{ $t('globalTopbar.apps') }}</h3
                >
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-for="item in getApplications"
              :key="item.id"
              @click="getPartnerTokenToRedirect(item.id)"
              :data-qa="`app-dropdown-${dashify(item.name)}`"
              :class="
                item.name === 'administrativeConsole' ? 'menu-selected' : ''
              "
            >
              <v-list-tile-content class="primary--text px-2">
                {{ $t(`applications.${item.name}.name`) }}
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-menu>

      <span class="line1"></span>
      <div class="ford-pro-icon-margin">
        <img
          class="ford-pro-icon"
          src="../../public/fordProLogo.png"
          alt="Ford Pro™ logo"
        />
      </div>

      <v-toolbar-title
        data-qa="global-topbar-applicationName"
        class="app-header-text ml-0 hidden-xs-only marketplace-heading-3"
      >
        {{ $t('globalTopbar.applicationName') }}
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-title
        v-if="!isRegistrationForm"
        data-qa="global-topbar-companyName"
        class="app-header-text hidden-xs-only mr-3 marketplace-heading-3"
      >
        {{ companyName }}
      </v-toolbar-title>
      <div class="mr-2" v-if="canViewNotifications()">
        <FcsUIWrapper>
          <fcs-notifications
            data-qa="notifications"
            :info-message-text="$t('notifications.infoMessageText')"
            :no-notifications-text="$t('notifications.noNotificationsText')"
            :drawer-header="$t('notifications.header')"
          />
        </FcsUIWrapper>
      </div>
      <v-avatar
        v-if="getUserInitials && getUserInitials !== ''"
        color="#00095B"
        size="32"
      >
        <span data-qa="global-topbar-userInitial" class="white--text">{{
          getUserInitials
        }}</span>
      </v-avatar>
      <v-avatar v-else-if="!isRegistrationForm" color="#00095B" size="32">
        <v-icon data-qa="global-topbar-noInitial">ic-user</v-icon>
      </v-avatar>

      <v-menu :nudge-bottom="45" v-if="!isRegistrationForm">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" :aria-label="$t('globalTopbar.chevron.label')">
            <v-icon
              data-qa="global-topbar-chevron"
              class="app-header-chevron"
              size="21"
              >ic-chevron-down</v-icon
            >
          </v-btn>
        </template>
        <v-list data-qa="topBar-items">
          <span
            :key="item.title"
            v-for="(item, index) in topBarNavItems"
            :data-qa="'navigation-item-' + dashify(item.title)"
          >
            <v-list-tile
              :key="index"
              :to="item.path"
              :disabled="item.isDisable"
            >
              <v-list-tile-title>{{ item.title }}</v-list-tile-title>
            </v-list-tile>
          </span>
        </v-list>
      </v-menu>
    </v-toolbar>
  </div>
</template>

<script>
import dashify from 'dashify';
import AuthService from '@/services/AuthService';
import { mapGetters } from 'vuex';

export default {
  name: 'TheGlobalTopBar',
  data: () => {
    return {
      menu: false,
    };
  },
  computed: {
    topBarNavItems: function () {
      const isOnboardPage = this.$route.name === 'onboard';
      return [
        {
          title: this.$t('settings.settings'),
          path: '/settings/user',
          userVisible: true,
          isDisable: isOnboardPage,
        },
        {
          title: this.$t('globalTopbar.privacy'),
          path: '/privacy',
          userVisible: true,
          isDisable: isOnboardPage,
        },
        {
          title: this.$t('globalTopbar.termsAndConditions'),
          path: '/termsAndConditions',
          userVisible: true,
          isDisable: isOnboardPage,
        },
        {
          title: this.$t('globalTopbar.logout'),
          icon: 'ic-logout',
          path: '/logout',
          userVisible: true,
          isDisable: false,
        },
      ];
    },
    companyName() {
      let fallback = this.$t('sidebar.defaultCompanyName').toUpperCase();
      try {
        return this.getCompanyName.toUpperCase() || fallback;
      } catch (e) {
        return fallback;
      }
    },
    isRegistrationForm() {
      return (
        this.$route.path === '/customer-signup' ||
        this.$route.path === '/third-party-signup'
      );
    },
    ...mapGetters('auth', [
      'getUserInitials',
      'getCompanyName',
      'getApplications',
      'canViewNotifications',
      'companyCountryCode',
    ]),
  },
  methods: {
    dashify(str, options) {
      return dashify(str, options);
    },
    getPartnerTokenToRedirect(applicationId) {
      AuthService.getPartnerTokenToRedirect(
        applicationId,
        this.companyCountryCode
      )
        .then((res) => {
          if (window.location.host !== new URL(res.data).host) {
            window.open(res.data, '_blank');
          } else {
            window.location.replace(res.data);
          }
        })
        .catch((__e) => {
          console.log('error calling partner token');
        });
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .v-toolbar__content {
    padding: 0;
    border-bottom: 0.1px solid #d6d7de;

    & > :first-child.v-btn--icon {
      margin-left: 5px;
    }
  }

  .fcs-icon-Notifications {
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
      height: 30px;

      g g {
        fill: #00095b;
      }
    }
  }

  .nav-item {
    list-style-type: none;
  }

  .notification-count b {
    margin-left: 3.5px;
    font-size: medium;
  }
}

.waffle-icon {
  padding-left: 2.45em;
  padding-right: 2.45em;
}

.line1 {
  box-sizing: border-box;
  height: 50px;
  width: 1px;
  border: 0.1px solid #c8c9c7;
}

.line2 {
  box-sizing: border-box;
  height: 16px;
  width: 2px;
  border: 1px solid #ffffff;
}

img.ford-pro-icon {
  width: 44px;
  height: 32px;
}

.v-menu__content.app-dropdown {
  left: 0 !important;
}

.app-dropdown-title {
  text-transform: uppercase;
}

.app-dropdown {
  ::v-deep.v-list__tile {
    padding-left: 20px;
  }
}

.menu-selected {
  ::v-deep.v-list__tile {
    background-color: rgba(16, 43, 78, 0.1);
  }

  .v-list__tile__content {
    font-weight: 500;
  }
}
.pointer-events-none {
  pointer-events: none;
}

.app-header-text {
  color: #00095b !important;
}

.app-header-chevron {
  color: #b8b9b9 !important;
  position: relative;
  top: -4px;
}

.ford-pro-icon-margin {
  margin: 2px 12px 0 24px;
}

.global-topbar {
  z-index: 7;
}
</style>
