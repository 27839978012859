<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app :class="{ loggedIn: getIsLoggedIn }" class="OneLinkNoTx">
    <NotificationQueue></NotificationQueue>
    <slot name="header" />
    <TheSidebar v-if="showSideBar" :items="sidebarItems"></TheSidebar>
    <v-content>
      <div
        class="continue-onboarding-button"
        v-if="
          getIsLoggedIn &&
          (!hasSignedFFMTermsAndConditions ||
            !hasSignedProductTermsAndConditions) &&
          $route.path === '/manage-cookies'
        "
      >
        <v-btn
          color="primary"
          class="white--text"
          @click="$router.push('/onboard')"
          data-qa="goToOnboardScreen"
          >{{ $t('cookieManagement.goToOnboard') }}
        </v-btn>
      </div>
      <router-view></router-view>
      <slot name="footer" />
    </v-content>
    <InitialSignOnDialogs></InitialSignOnDialogs>
    <slot name="dialogs" v-bind:canUseFFMApplication="canUseFFMApplication" />
  </v-app>
</template>

<script>
import TheSidebar from '@/components/TheSidebar';
import { mapGetters } from 'vuex';
import NotificationQueue from '@/components/NotificationQueue';
import InitialSignOnDialogs from '@/components/InitialSignOnDialogs';
import AnalyticsService from '@/services/AnalyticsService';
import SubscribeToStore from '@/mixins/SubscribeToStore';

export default {
  name: 'App',
  components: {
    TheSidebar,
    NotificationQueue,
    InitialSignOnDialogs,
  },
  mixins: [SubscribeToStore('subscribeCallBack')],
  props: {
    sidebarItems: Array,
  },
  data() {
    return {
      notificationRecipient: '',
      analyticsUser: '',
      userActivityTimeout: null,
    };
  },
  mounted: async function () {
    if (localStorage.getItem('firstEvent') === null) {
      localStorage.setItem('firstEvent', 'true');
    }
  },
  beforeDestroy() {
    // Need to place below code at a place where the user logs out
    // Source: https://developers.amplitude.com/docs/setting-custom-user-ids#logging-out-and-anonymous-users
    AnalyticsService.destroyAmplitudeSettings();
  },
  computed: {
    ...mapGetters('auth', [
      'getApplications',
      'getIsLoggedIn',
      'canViewNotifications',
    ]),
    ...mapGetters('termsAndConditions', [
      'hasFetchedTermsAndConditions',
      'hasSignedFFMTermsAndConditions',
      'hasSignedProductTermsAndConditions',
      'canViewOnboardPage',
    ]),
    canUseFFMApplication() {
      for (const item of this.getApplications) {
        if (
          item.name === 'administrativeConsole' ||
          item.name === 'admin_internal'
        ) {
          return true;
        }
      }
      return false;
    },
    showSideBar() {
      let fetchedAndSignedTermsAndConditions =
        this.hasFetchedTermsAndConditions &&
        this.hasSignedFFMTermsAndConditions &&
        this.hasSignedProductTermsAndConditions;
      let isReadyToOnboard =
        !fetchedAndSignedTermsAndConditions ||
        this.$route.path.startsWith('/onboard');
      let isOnboarding = this.canViewOnboardPage && isReadyToOnboard;
      return (
        this.getIsLoggedIn &&
        !isOnboarding &&
        this.canUseFFMApplication &&
        this.sidebarItems
      );
    },
  },
  methods: {
    subscribeCallBack(mutation) {
      if (mutation.type === 'auth/SET_USER_INFO') {
        let userId = mutation.payload.userId;
        if (
          this.canViewNotifications() &&
          this.notificationRecipient !== userId
        ) {
          this.$fcsNotification.notificationsWebsocketConnector.connectNotificationWebsocket(
            true
          );
          this.notificationRecipient = userId;
        }
        if (this.analyticsUser !== userId) {
          AnalyticsService.setUserId(userId);
          this.analyticsUser = userId;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import './styles/styles.scss';
@import './assets/compiled-icons/icons.css';
@import './styles/font-awesome-pro/scss/light.scss';

.app-background-color {
  background-color: #eee;
}

.continue-onboarding-button {
  position: absolute;
  right: 20px;
  top: 2%;

  .v-btn {
    border-radius: 5px;
    text-transform: none;
  }
}

.v-tabs__item {
  outline-offset: -2px;
}
</style>
