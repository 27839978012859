import { AccountConstants } from '@/constants';
import store from '@/store';
import fpgConfiguration from '@/store/fpgConfiguration';

class AmplitudeService {
  amplitude;
  constructor() {
    this.amplitude = require('amplitude-js');
    if (
      AccountConstants.PRODUCTION_HOST_NAMES.includes(window.location.hostname)
    ) {
      this.amplitude
        .getInstance()
        .init(AccountConstants.AMPLITUDE_API_KEY_PROD);
    } else {
      this.amplitude.getInstance().init(AccountConstants.AMPLITUDE_API_KEY_DEV);
    }
  }

  createLogEventPromise(eventType, eventProperties) {
    return new Promise((resolve) => {
      this.amplitude.getInstance().logEvent(eventType, eventProperties, () => {
        resolve();
      });
    });
  }

  trackUserEvents(title) {
    const userProperties = {
      'First Name': store.getters['auth/getUserInfo'].firstName,
      Email: store.getters['auth/getUserEmail'],
      Company:
        store.getters['auth/getUserInfo'].companyInformationResponse.name,
    };
    const app = fpgConfiguration.state.isTileMode ? 'fpg' : 'standalone';
    this.amplitude.getInstance().setUserProperties(userProperties);
    let promises = [];
    if (JSON.parse(localStorage.getItem('firstEvent'))) {
      localStorage.setItem('firstEvent', false);
      promises.push(
        this.createLogEventPromise('Start Session', null),
        this.createLogEventPromise(title.toUpperCase(), {
          Source: title,
          App: app,
        })
      );
    } else {
      promises.push(
        this.createLogEventPromise(title, {
          Source: title,
          App: app,
        })
      );
    }
    return Promise.all(promises);
  }

  setUserId(userId) {
    this.amplitude.setUserId(userId);
  }

  destroy() {
    this.amplitude.setUserId(null);
    this.amplitude.getInstance().regenerateDeviceId(); // If we remove this, there is a possibility we can track whether two users are using the same device or not
  }
}

export default class AmplitudeServiceProvider {
  static amplitudeService;

  static getAmplitudeServiceInstance() {
    if (!this.amplitudeService) {
      return new AmplitudeService();
    }
    return this.amplitudeService;
  }
}
