<!--suppress CssInvalidPseudoSelector -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    :max-width="maxWidth"
    v-model="dialog"
    scrollable
    :persistent="persistent"
    @keydown.esc="cancel"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <ErrorCard
      v-if="displayError"
      :error="{
        title: errorTitle,
        description: errorDescription,
        action: errorAction,
      }"
      @close="cancel"
    />
    <v-card v-else :data-qa="`confirmation-dialog-${dataQaKey}`">
      <v-card-title>
        <h1 class="title primary--text">{{ title }}</h1>
      </v-card-title>
      <v-card-text v-if="message" style="padding-bottom: 8px">
        <p
          class="body-1"
          style="margin: auto; font-weight: 500"
          v-html="message"
        />
      </v-card-text>
      <v-card-text
        style="overflow-y: hidden; padding-top: 0; padding-bottom: 8px"
      >
        <slot name="link"></slot>
      </v-card-text>
      <v-divider v-if="!hideContent && displayDivider"></v-divider>
      <v-card-text
        v-if="!hideContent"
        :id="`confirmation-dialog-content-${dataQaKey}`"
        data-qa="confirmation-dialog-content"
        :style="{ height: height }"
      >
        <slot name="content"></slot>
      </v-card-text>
      <v-card-text
        v-if="displayFooter"
        :id="`confirmation-dialog-footer-${dataQaKey}`"
        class="body-1"
      >
        <slot name="footer"></slot>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <cancel-button
          v-if="showCancelButton"
          ref="cancelBtn"
          data-qa="confirmation-dialog-cancel"
          :color="displayConfirm ? '' : 'primary'"
          @click="cancel"
          @keydown.tab.prevent="trapFocus"
        >
          {{ cancelingText }}
        </cancel-button>
        <slot name="confirmation-section">
          <confirm-button
            v-if="displayConfirm"
            ref="confirmBtn"
            data-qa="confirmation-dialog-confirm"
            @click="
              $emit('confirm');
              dialog = false;
            "
            @keydown.tab.prevent="trapFocus"
            :disabled="confirmDisabled"
          >
            {{ acceptingText }}
          </confirm-button>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dashify from 'dashify';
import ErrorCard from '@/components/utility-components/dialogs/ErrorCard';
import CancelButton from '@/components/utility-components/dialogs/CancelButton';
import ConfirmButton from '@/components/utility-components/dialogs/ConfirmButton';

export default {
  name: 'ConfirmationDialog',
  components: { CancelButton, ConfirmButton, ErrorCard },
  data() {
    return {
      showDialog: false,
    };
  },
  computed: {
    dialog: {
      get: function () {
        return this.showDialog || this.enableDialog;
      },
      set: function (v) {
        this.showDialog = v;
        if (v === false) {
          this.$emit('setEnableDialogFalse');
        } else {
          this.$emit('setEnableDialogTrue');
        }
      },
    },
    acceptingText: function () {
      if (
        this.acceptanceText === undefined ||
        this.acceptanceText === null ||
        this.acceptanceText === ''
      ) {
        return this.$t('confirmationDialog.confirm');
      }
      return this.acceptanceText;
    },
    cancelingText: function () {
      if (this.cancelAndContinue) {
        return this.$t('confirmationDialog.cancelAndContinue');
      } else {
        if (
          this.cancellationText === undefined ||
          this.cancellationText === null ||
          this.cancellationText === ''
        ) {
          return this.$t('confirmationDialog.cancel');
        }
        return this.cancellationText;
      }
    },
  },
  props: {
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    title: String,
    errorTitle: String,
    errorDescription: String,
    errorAction: String,
    message: String,
    acceptanceText: String,
    cancellationText: String,
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    displayDivider: {
      type: Boolean,
      default: true,
    },
    displayConfirm: {
      type: Boolean,
      default: true,
    },
    enableDialog: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '200px',
    },
    maxWidth: {
      type: String,
      default: '480px',
    },
    hideContent: {
      type: Boolean,
      default: false,
      required: false,
    },
    dataQaKey: String,
    displayError: {
      type: Boolean,
      default: false,
      required: false,
    },
    cancelAndContinue: {
      type: Boolean,
      default: false,
    },
    displayFooter: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    dashify,
    cancel() {
      if (this.cancelAndContinue) {
        this.$emit('cancelAndContinue');
      } else {
        this.$emit('cancel');
      }
      this.dialog = false;
    },
    trapFocus(event) {
      if (event.target === this.$refs.cancelBtn.$el) {
        this.$refs.confirmBtn.$el.focus();
      } else {
        this.$refs.cancelBtn.$el.focus();
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-dialog {
  max-width: 480px;
}

.v-dialog__container {
  display: none !important;
}
</style>
