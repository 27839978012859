import Vue from 'vue';
import AmplitudeServiceProvider from '@/services/AmplitudeService';
import oneTrustService from '@/services/OneTrustService';

export default new Vue({
  methods: {
    doTrackUserEvents(title) {
      if (
        oneTrustService.isLoaded() &&
        oneTrustService.isPerformanceCookiesAllowed()
      ) {
        try {
          return AmplitudeServiceProvider.getAmplitudeServiceInstance().trackUserEvents(
            title
          );
        } catch (e) {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    },
    setUserId(userId) {
      if (
        oneTrustService.isLoaded() &&
        oneTrustService.isPerformanceCookiesAllowed()
      ) {
        AmplitudeServiceProvider.getAmplitudeServiceInstance().setUserId(
          userId
        );
      }
    },
    destroyAmplitudeSettings() {
      if (
        oneTrustService.isLoaded() &&
        oneTrustService.isPerformanceCookiesAllowed()
      ) {
        AmplitudeServiceProvider.getAmplitudeServiceInstance().destroy();
      }
    },
  },
});
