<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FcsUIWrapper',
};
</script>

<style lang="scss" scoped>
::v-deep {
  @import 'src/styles/fcs-ui';

  .b-sidebar {
    max-height: none;
  }
}
</style>
