<!--suppress CssInvalidPseudoSelector -->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog
    @keydown.esc="hideDialog"
    data-app
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    v-model="dialog"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <template>
      <v-card
        class="app-background-color"
        :data-qa="dashify(`product-upgrade-stepper-` + dataQaKey)"
      >
        <v-toolbar style="background-color: white">
          <close-button @close-clicked="hideDialog" />
          <v-toolbar-title
            class="primary--text"
            data-qa="product-upgrade-stepper-title"
          >
            {{ getToolbarTitle }}
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-spacer />
        <v-container fluid>
          <v-layout align-center justify-center>
            <v-flex class="v-flex-groupDialog pb-5" xl7 lg12 md12 s12 xs12>
              <v-card>
                <v-stepper v-model="step" :key="stepperRefresh">
                  <v-layout align-center justify-center>
                    <v-flex xl4 lg6 md6 s6 xs8>
                      <v-stepper-header>
                        <template v-for="i in totalNumberOfSteps">
                          <v-stepper-step
                            :key="i"
                            :complete="step > i"
                            :step="i"
                          />
                          <v-divider
                            :key="i + 'divider'"
                            v-if="i < totalNumberOfSteps"
                          />
                        </template>
                      </v-stepper-header>
                    </v-flex>
                  </v-layout>

                  <v-layout
                    align-center
                    align-content-center
                    class="pa-2"
                    justify-center
                    row
                  >
                    <v-flex xl12 lg12 s12 xs12 :class="mediumContentSize">
                      <v-stepper-items>
                        <v-stepper-content
                          :step="vehicleSelectionStep"
                          class="vehicle-selection-step"
                        >
                          <VehicleSelectionForm
                            v-if="!skipVehicleSelectionStep"
                          ></VehicleSelectionForm>
                        </v-stepper-content>

                        <v-stepper-content :step="billingStep">
                          <SpinnerWrapper :is-loading="isLoading">
                            <v-container class="pt-0 pb-0">
                              <h2
                                class="headline primary--text stepper-header text-uppercase"
                              >
                                {{
                                  $t('productUpgradeStepper.stepOneTitle')
                                }}</h2
                              >
                              <BillingInformationForm
                                v-model="validBillingInfo"
                                :is-complimentary="isComplimentary"
                                :hide-title="true"
                              />
                              <v-container
                                v-if="shouldShowVatIdAddEdit"
                                data-qa="vat-id-components"
                                style="padding: 16px"
                              >
                                <v-layout row wrap data-qa="child-accounts">
                                  <v-flex v-if="!isFetchingData" xs12>
                                    <div>
                                      <span class="font-weight-bold mb-1">
                                        <p data-qa="additional-vat-id-text">{{
                                          $t(
                                            getCountryTaxDisplayType.additionalTaxText
                                          )
                                        }}</p>
                                      </span>
                                    </div>
                                    <EditChildAccountDialog :isAdd="true">
                                      <template v-slot:activator="{ on }">
                                        <a
                                          v-on="on"
                                          data-qa="add-vat-id-button"
                                          >{{
                                            $t(
                                              getCountryTaxDisplayType.additionalTaxBtn
                                            )
                                          }}</a
                                        >
                                      </template>
                                    </EditChildAccountDialog>
                                  </v-flex>
                                  <v-flex
                                    xs12
                                    sm6
                                    md4
                                    lg4
                                    class="childAccount"
                                    :key="childAccount.index"
                                    v-for="childAccount in visibleChildAccounts"
                                  >
                                    <ChildAccountInformation
                                      data-qa="billing-child"
                                      :childAccount="childAccount"
                                      :childAccountIndex="childAccount.index"
                                    />
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-container>
                          </SpinnerWrapper>
                        </v-stepper-content>

                        <v-stepper-content
                          v-if="!skipMultiProductSelectionStep"
                          :step="multiProductSelectionStep"
                        >
                          <MultiProductSelection
                            v-if="step === multiProductSelectionStep"
                            :pre-selected-products="localProducts"
                          />
                        </v-stepper-content>

                        <v-stepper-content
                          v-if="!skipTermsAndConditionsStep"
                          :step="termsAndConditionsStep"
                        >
                          <div v-if="step === termsAndConditionsStep">
                            <AcceptTermsAndConditions
                              v-if="canSignTermsAndConditions"
                              :title="
                                $t(
                                  `addProduct.termsAndConditions.title`
                                ).toUpperCase()
                              "
                              :description="
                                $t('addProduct.termsAndConditions.description')
                              "
                              :terms-and-conditions="
                                latestTermsAndConditions.legalLanguage
                              "
                              :legal-language-by-llid-id="
                                latestTermsAndConditions.legalLanguageByLlidId
                              "
                              :disable-agree="hasSignedTermsAndConditionsInFlow"
                            />
                            <ProductTermsAndConditions
                              v-else
                              :is-upgrade="isUpgrade"
                              :products="localProducts"
                            />
                          </div>
                        </v-stepper-content>

                        <v-stepper-content :step="confirmationStep">
                          <ProductAddUpgradeConfirmation
                            v-if="step === confirmationStep"
                            :is-complimentary="isComplimentary"
                            :is-upgrade="isUpgrade"
                            :products="localProducts"
                            :show-reminder="showReminder"
                            :old-product-family-name="oldProductFamilyName"
                            :display-terms-and-conditions-download="
                              skipTermsAndConditionsStep &&
                              canSignTermsAndConditions
                            "
                            data-qa="confirmation-step"
                          />
                        </v-stepper-content>
                      </v-stepper-items>

                      <v-card-actions class="no-padding mb-2 mt-4">
                        <v-btn
                          block
                          data-qa="product-upgrade-stepper-cancel"
                          flat
                          medium
                          class="primary--text text-none"
                          style="margin-left: 15%"
                          @click="hideDialog()"
                        >
                          {{ $t('productUpgradeStepper.cancel') }}
                        </v-btn>
                        <v-btn
                          v-if="step > 1"
                          block
                          data-qa="product-upgrade-stepper-go-back"
                          flat
                          medium
                          outline
                          class="primary--text text-none"
                          style="margin-left: 5%; margin-right: 5%"
                          @click="step -= 1"
                        >
                          {{ $t('productUpgradeStepper.goBack') }}
                        </v-btn>
                        <v-btn
                          block
                          class="text-none"
                          color="primary"
                          data-qa="product-upgrade-stepper-proceed"
                          medium
                          @click="onContinue()"
                          style="margin-right: 15%"
                          :disabled="disableContinue"
                        >
                          <loading-spinner
                            data-qa="continue-spinner"
                            v-if="isProcessingToContinue"
                            color="white"
                            :size="20"
                            :width="2"
                          />
                          <p
                            v-else
                            class="mb-0"
                            data-qa="terms-and-conditions-download-text"
                          >
                            {{ continueButtonText }}
                          </p>
                        </v-btn>
                      </v-card-actions>
                    </v-flex>
                  </v-layout>
                </v-stepper>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
      <ErrorDialog
        :error="error"
        :show-dialog="showErrorDialog"
        @close-dialog="showErrorDialog = false"
      >
      </ErrorDialog>
      <ProcessingDialog
        :show-processing="showProcessDialog"
        :message="processDialogText"
      />
    </template>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AccountConstants } from '@/constants';
import dashify from 'dashify';
import ErrorDialog from '@/components/utility-components/dialogs/ErrorDialog';
import BillingInformationForm from '@/components/billing/BillingInformationForm';
import ProductService from '@/services/ProductService';
import CompanyService from '@/services/CompanyService';
import InvoiceService from '@/services/InvoiceService';
import ProcessingDialog from '@/components/ProcessingDialog';
import ChildAccountInformation from '@/components/billing/ChildAccountInformation';
import ProductTermsAndConditions from '@/components/product/ProductTermsAndConditions';
import EditChildAccountDialog from '../EditChildAccountDialog';
import SpinnerWrapper from '@/components/utility-components/SpinnerWrapper';
import MultiProductSelection from '@/components/product/MultiProductSelection';
import ProductAddUpgradeConfirmation from './ProductAddUpgradeConfirmation';
import SubscribeToStore from '@/mixins/SubscribeToStore';
import AcceptTermsAndConditions from '@/components/terms-and-conditions/AcceptTermsAndConditions';
import OnboardService from '@/services/OnboardService';
import ErrorMessageService from '@/services/ErrorMessageService';
import VehicleSelectionForm from '@/components/product/VehicleSelectionForm';
import CloseButton from '@/components/utility-components/CloseButton';
import LoadingSpinner from '@/components/utility-components/LoadingSpinner';
import { get } from 'vuex-pathify';

export default {
  name: 'ProductUpgradeStepper',
  mixins: [SubscribeToStore('subscribeCallback')],
  components: {
    LoadingSpinner,
    CloseButton,
    VehicleSelectionForm,
    AcceptTermsAndConditions,
    ProductAddUpgradeConfirmation,
    MultiProductSelection,
    SpinnerWrapper,
    BillingInformationForm,
    ErrorDialog,
    ProcessingDialog,
    ChildAccountInformation,
    ProductTermsAndConditions,
    EditChildAccountDialog,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
      required: false,
    },
    oldProductFamilyId: String,
    oldProductFamilyName: String,
    isUpgrade: {
      type: Boolean,
      default: false,
    },
    isComplimentary: {
      type: Boolean,
      default: false,
    },
    showReminder: {
      type: Boolean,
      default: false,
    },
    cancelRedirectRoute: {
      type: String,
      default: null,
    },
    dataQaKey: {
      type: String,
      required: true,
    },
  },
  async beforeMount() {
    this.initializeLocalProducts();
  },
  data() {
    return {
      legalLanguage: '',
      defaultPrice: 0.0,
      defaultProducts: [],
      showProcessing: false,
      processingMessage: '',
      accountConstants: AccountConstants,
      error: {},
      showErrorDialog: false,
      step: 1,
      dialog: false,
      productFamilyLegalLanguageMap: {},
      validBillingInfo: false,
      defaultPriceSet: false,
      defaultProductsRetrieved: false,
      showProcessDialog: false,
      isLoading: true,
      localProducts: [],
      isProcessingToContinue: false,
      displayRfidAssociationMessage: '',
      latestTermsAndConditions: {},
      hasSignedTermsAndConditionsInFlow: false,
      stepperRefresh: 0,
    };
  },
  computed: {
    disableContinue() {
      if (this.isProcessingToContinue) {
        return true;
      }
      switch (this.step) {
        case this.vehicleSelectionStep:
          return Object.keys(this.selected).length === 0;
        case this.billingStep:
          return (
            !this.validBillingInfo ||
            this.isFetchingData ||
            this.isFetchingChildAccountData
          );
        case this.multiProductSelectionStep:
          return this.selectedLength === 0;
        case this.termsAndConditionsStep:
          const useOneTermsAndConditions = this.canSignTermsAndConditions;
          let termsAndConditionsFetchedAndAgreed;
          if (useOneTermsAndConditions) {
            termsAndConditionsFetchedAndAgreed =
              !this.checkedAgreeTermsAndConditions ||
              !this.latestTermsAndConditions.legalLanguage;
          } else {
            termsAndConditionsFetchedAndAgreed =
              !this.checkedAgreeTermsAndConditions ||
              !this.hasFetchedTermsAndConditions;
          }
          if (this.isComplimentary) {
            return termsAndConditionsFetchedAndAgreed;
          }
          let defaultProductsAndPriceSet =
            !this.defaultProductsRetrieved || !this.defaultPriceSet;
          return (
            termsAndConditionsFetchedAndAgreed || defaultProductsAndPriceSet
          );
        case this.confirmationStep:
          return (
            this.showProcessDialog ||
            (this.isUpgrade && !this.agreedToCostIncrease)
          );
        default:
          return false;
      }
    },
    processDialogText() {
      if (this.isUpgrade) {
        return this.$t('productUpgradeStepper.processing.upgrade');
      }
      return this.$t('productUpgradeStepper.processing.add');
    },
    ...get('productUpgrade', ['agreedToCostIncrease', 'skusToUpgrade']),
    ...mapGetters('termsAndConditions', [
      'checkedAgreeTermsAndConditions',
      'hasFetchedTermsAndConditions',
      'productTermsAndConditionsMap',
    ]),
    ...mapGetters('auth', ['getCompanyId', 'canSignTermsAndConditions']),
    ...mapGetters('vehicle', ['selected', 'vehicleFilter']),
    ...mapGetters('companyBillingInfo', [
      'allBillingInfo',
      'addressValidationObject',
      'isFetchingChildAccountData',
      'visibleChildAccounts',
      'hiddenChildAccounts',
      'isFetchingData',
      'getCountryTaxDisplayType',
    ]),
    ...mapGetters('multiProductSelection', [
      'getSelectedArray',
      'selectedLength',
    ]),
    ...mapGetters('configuration', [
      'publicChargingSkus',
      'shouldShowVehicleSelectionUpgradeStep',
      'maxUpgradableVehicleCount',
    ]),
    ...mapGetters('vehicle', ['selected']),
    shouldShowVatIdAddEdit() {
      let isAPublicChargingSku =
        this.defaultProducts.filter((value) =>
          this.publicChargingSkus.includes(value.sku)
        ).length > 0;
      let isComplimentaryProductPublicCharging = false;

      if (this.isComplimentary) {
        isComplimentaryProductPublicCharging =
          this.products.filter((value) =>
            this.publicChargingSkus.includes(value.productSku)
          ).length > 0;
      }

      return (
        isAPublicChargingSku ||
        isComplimentaryProductPublicCharging ||
        this.visibleChildAccounts.length > 0
      );
    },
    continueButtonText() {
      if (this.step === this.confirmationStep && this.isUpgrade) {
        return this.$t('productUpgradeStepper.upgrade');
      } else if (this.step === this.confirmationStep && this.isComplimentary) {
        return this.$t('products.complimentaryProducts.activate');
      } else if (this.step === this.confirmationStep) {
        return this.$tc(
          'productUpgradeStepper.addProducts',
          this.localProducts.length
        );
      } else {
        return this.$t('productUpgradeStepper.continue');
      }
    },
    productFamilyName() {
      return this.localProducts[0].productFamilyName;
    },
    productFamilyId() {
      return this.localProducts[0].productFamilyId;
    },
    getToolbarTitle() {
      if (this.isComplimentary) {
        return this.$t('products.complimentaryProducts.activationTitle');
      } else if (this.isUpgrade) {
        if (this.localProducts.length > 0) {
          return this.$t('productUpgradeStepper.title', {
            productFamilyName: this.productFamilyName,
          });
        } else {
          return '';
        }
      } else {
        return this.$t('productUpgradeStepper.titleAdd');
      }
    },
    isUpgradeOrComplimentary() {
      return this.isUpgrade || this.isComplimentary;
    },
    vehicleSelectionStep() {
      return this.skipVehicleSelectionStep ? undefined : 1;
    },
    billingStep() {
      return this.skipVehicleSelectionStep ? 1 : 2;
    },
    multiProductSelectionStep() {
      if (this.skipMultiProductSelectionStep) {
        return undefined;
      } else {
        return this.skipTermsAndConditionsStep
          ? this.totalNumberOfSteps - 1
          : this.totalNumberOfSteps - 2;
      }
    },
    skipMultiProductSelectionStep() {
      return this.isUpgradeOrComplimentary;
    },
    skipVehicleSelectionStep() {
      return (
        !this.isUpgrade ||
        !this.shouldShowVehicleSelectionUpgradeStep ||
        !this.vehicleFilter.vehicleList ||
        this.vehicleFilter.vehicleList.length === 0 ||
        this.vehicleFilter.vehicleList.length > this.maxUpgradableVehicleCount
      );
    },
    termsAndConditionsStep() {
      return this.skipTermsAndConditionsStep
        ? undefined
        : this.totalNumberOfSteps - 1;
    },
    skipTermsAndConditionsStep() {
      return this.latestTermsAndConditions.hasSigned;
    },
    confirmationStep() {
      return this.totalNumberOfSteps;
    },
    totalNumberOfSteps() {
      let total = 5;
      if (this.skipTermsAndConditionsStep) {
        total = total - 1;
      }
      if (this.skipMultiProductSelectionStep) {
        total = total - 1;
      }
      if (this.skipVehicleSelectionStep) {
        total = total - 1;
      }
      return total;
    },
    mediumContentSize() {
      return this.step === this.multiProductSelectionStep ? 'md8' : 'md12';
    },
  },
  methods: {
    ...mapActions('notification', [
      'addSuccessNotification',
      'addErrorNotification',
      'addInformationNotification',
    ]),
    ...mapActions('multiProductSelection', ['clearSelected']),
    ...mapActions('termsAndConditions', [
      'setCheckedAgreeTermsAndConditions',
      'setProductTermsAndConditionsMap',
      'fetchLatestTermsAndConditions',
    ]),
    ...mapActions('companyBillingInfo', [
      'fetchCompanyBillingInfo',
      'fetchChildAccountsForAllCountries',
      'updateChildAccountAddress',
    ]),
    ...mapActions('product', ['refreshProduct']),
    ...mapActions('cache', ['invalidate']),
    dashify,
    initializeLocalProducts() {
      this.localProducts = this.products === undefined ? [] : this.products;
    },
    subscribeCallback(mutation) {
      if (
        this.dialog &&
        mutation.type === 'multiProductSelection/SELECTED_CHANGED'
      ) {
        this.localProducts = this.getSelectedArray;
      }
    },
    async onContinue() {
      this.isProcessingToContinue = true;
      if (this.step === this.billingStep) {
        await InvoiceService.validateBillingAddress(
          this.addressValidationObject
        )
          .then((res) => {
            if (res.data === true) {
              this.updateChildAccountAddress();
              this.step += 1;
            } else {
              this.addErrorNotification(
                this.$t(
                  'productUpgradeStepper.snackBarMessage.addressValidationFailure'
                )
              );
            }
          })
          .catch((error) => {
            console.log(error);
            this.addErrorNotification(
              this.$t(
                'productUpgradeStepper.snackBarMessage.addressValidationFailure'
              )
            );
          });
      } else if (this.step === this.multiProductSelectionStep) {
        await this.getDefaultProducts();
        this.step += 1;
      } else if (
        this.step === this.termsAndConditionsStep &&
        this.canSignTermsAndConditions
      ) {
        await this.acceptTermsAndConditions();
        if (!this.showErrorDialog) {
          this.step += 1;
        }
      } else if (this.step === this.confirmationStep) {
        this.confirmProductAdd();
      } else {
        this.step += 1;
      }
      this.isProcessingToContinue = false;
    },
    hideDialog() {
      this.resetData();
      this.$emit('close-dialog');
    },
    getDefaultPrice() {
      if (this.isUpgrade) {
        if (this.localProducts.length > 1) {
          throw new Error('cannot upgrade multiple products at once');
        }
        let productToUpgrade = this.defaultProducts.filter(
          (dp) => dp.sku === this.localProducts[0].productSku
        )[0];
        if (productToUpgrade === undefined) {
          throw new Error('no SKU matched, unable to determine price');
        }
        this.defaultPrice = productToUpgrade.price;

        if (
          this.localProducts[0].price === null ||
          this.localProducts[0].price === undefined
        ) {
          this.localProducts[0].price = productToUpgrade.price;
          this.localProducts[0].currencyCode = productToUpgrade.currencyCode;
        }
      }
      this.defaultPriceSet = true;
    },
    async getDefaultProducts() {
      const method = this.isUpgrade
        ? 'getDefaultProductsForUpgrade'
        : 'getDefaultProductsForAdd';
      this.defaultProducts = [];
      for (const product of this.localProducts) {
        await ProductService[method](product.productFamilyId)
          .then((res) => {
            this.defaultProducts = this.defaultProducts.concat(res.data);
            this.getDefaultPrice();
            this.defaultProductsRetrieved = true;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async acceptTermsAndConditions() {
      if (!this.hasSignedTermsAndConditionsInFlow) {
        await OnboardService.agreeTermsAndConditions({
          llid: this.latestTermsAndConditions.llid,
          legalLanguage: this.latestTermsAndConditions.legalLanguage,
          productFamilyId: null,
        })
          .then(() => {
            this.invalidate('termsAndConditions/setLatestTermsAndConditions');
            this.hasSignedTermsAndConditionsInFlow = true;
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    confirmProductAdd() {
      this.showProcessDialog = true;
      CompanyService.addCompanyProduct(
        this.getCompanyId,
        this.buildCompanyProductAddRequest()
      )
        .then(() => {
          this.addSuccessNotification(this.getSuccessNotificationMessage());
          this.showRfidAssociationMessage();
          this.refreshProduct();
          this.$emit('confirm-success');
          this.resetData();
          this.showProcessDialog = false;
        })
        .catch((error) => {
          this.handleProductAddError(error);
          console.log(error);
          this.showProcessDialog = false;
        });
    },
    buildCompanyProductAddRequest() {
      let companyProductAddRequest = {};
      let skus = new Set();
      let consents = [];
      const termsAndConditionsByProduct = !this.canSignTermsAndConditions;
      if (this.isComplimentary) {
        let skusToEnroll = new Set();
        for (let product of this.localProducts) {
          skusToEnroll.add(product.productSku);
          if (product.rfidConfigurable) {
            this.displayRfidAssociationMessage = product.productFamilyName;
          }
          skus = new Set([...skus, ...product.defaultProductSkus]);
        }
        companyProductAddRequest = { skusToEnroll: Array.from(skusToEnroll) };
        if (termsAndConditionsByProduct) {
          consents = this.localProducts.flatMap((product) => {
            const legalLanguageObjectMapElement =
              this.productTermsAndConditionsMap[
                product.productFamilyId.toLowerCase()
              ];
            let consentsForProduct = [
              {
                llid: legalLanguageObjectMapElement.llid,
                languageCode:
                  legalLanguageObjectMapElement.legalLanguageNameCode,
                sku: product.productSku,
                legalLanguageByLlid:
                  legalLanguageObjectMapElement.legalLanguageByLlid,
              },
            ];
            product.defaultProductSkus.forEach((defaultProductSku) => {
              consentsForProduct.push({
                llid: legalLanguageObjectMapElement.llid,
                languageCode:
                  legalLanguageObjectMapElement.legalLanguageNameCode,
                sku: defaultProductSku,
                legalLanguageByLlid:
                  legalLanguageObjectMapElement.legalLanguageByLlid,
              });
            });
            return consentsForProduct;
          });
        }
      } else {
        skus = this.defaultProducts.map((dp) => {
          return dp.sku;
        });
        if (termsAndConditionsByProduct) {
          consents = this.defaultProducts.map((dp) => {
            const legalLanguage =
              this.productTermsAndConditionsMap[
                dp.productFamilyId.toLowerCase()
              ];
            return {
              llid: legalLanguage.llid,
              languageCode: legalLanguage.legalLanguageNameCode,
              sku: dp.sku,
              legalLanguageByLlid: legalLanguage.legalLanguageByLlid,
            };
          });
        }
      }
      if (this.isUpgrade) {
        companyProductAddRequest = {
          ...companyProductAddRequest,
          upgradePriceAgreement: JSON.stringify(this.skusToUpgrade),
        };
      }
      return {
        ...companyProductAddRequest,
        skus: Array.from(skus),
        consents: consents,
        billingInfo: this.allBillingInfo,
        upgrade: this.isUpgrade,
        vehicleFilter: this.skipVehicleSelectionStep
          ? null
          : Object.keys(this.selected),
      };
    },
    showRfidAssociationMessage() {
      if (this.displayRfidAssociationMessage !== '') {
        this.addInformationNotification(
          this.$t('product.rfidAssociationMessage', {
            productFamilyName: this.displayRfidAssociationMessage,
          })
        );
      }
    },
    resetData() {
      this.dialog = false;
      this.clearSelected();
      this.setCheckedAgreeTermsAndConditions(false);
      this.setProductTermsAndConditionsMap({});
      this.displayRfidAssociationMessage = '';
      this.step = 1;
      if (this.cancelRedirectRoute) {
        this.$router.push(this.cancelRedirectRoute);
      }
    },
    handleProductAddError() {
      this.error = {
        title: 'error.addProductFailed.title',
        description: 'error.addProductFailed.description',
        action: 'error.addProductFailed.action',
      };
      this.showErrorDialog = true;
    },
    handleError(error) {
      this.error = ErrorMessageService.getErrorMessage(error);
      this.showErrorDialog = true;
    },
    getSuccessNotificationMessage() {
      if (this.isUpgrade) {
        return this.$t('productUpgradeStepper.snackBarMessage.success', {
          oldProductFamily: this.$tf(
            'productFamily.' + this.oldProductFamilyId,
            this.oldProductFamilyName
          ),
          newProductFamily: this.$tf(
            'productFamily.' + this.productFamilyId,
            this.productFamilyName
          ),
        });
      } else {
        return this.$tc(
          'productUpgradeStepper.snackBarMessage.successForProductAdd',
          this.localProducts.length,
          {
            productFamily: this.$tf(
              'productFamily.' + this.productFamilyId,
              this.productFamilyName
            ),
            count: this.localProducts.length,
          }
        );
      }
    },
  },
  watch: {
    async dialog(val) {
      if (val) {
        this.stepperRefresh++;
        this.initializeLocalProducts();
        this.isLoading = true;
        await this.fetchCompanyBillingInfo();
        await this.fetchLatestTermsAndConditions()
          .then((res) => {
            if (res.hasSigned === null) {
              this.latestTermsAndConditions = {};
            } else {
              this.latestTermsAndConditions = res;
            }
          })
          .catch(() => {
            this.latestTermsAndConditions = {};
          });
        if (!this.isComplimentary) {
          await this.getDefaultProducts();
          await this.fetchChildAccountsForAllCountries(
            this.defaultProducts.map((dp) => {
              return dp.sku;
            })
          );
        } else {
          await this.fetchChildAccountsForAllCountries(
            this.products.map((p) => p.productSku)
          );
        }
        this.isLoading = false;
      }
    },
    cancelRedirectRoute: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.dialog = true;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper-header {
  text-align: center;
  padding-bottom: 2%;
  font-size: 22px !important;
  color: #292e35 !important;
}

.heading-text {
  margin-left: 2em;
  margin-top: 2em;
  color: #292e35;
}

.button-gap {
  margin-left: 5px;
  margin-right: 5px;
}

.v-stepper__header {
  box-shadow: none;
  webkit-box-shadow: none;
}

.app-background-color {
  background-color: #eee;
}

.group-add-container {
  padding: 24px;
}

.action-button-align-right {
  text-align: right;
}

.scrollable {
  max-height: 150px;
  overflow-y: scroll;
  padding-right: 12px;
}

.no-padding {
  padding: 0;
}

::v-deep .v-messages__message {
  hyphens: initial;
}

.shipping-form-divider {
  margin-top: 2em;
  margin-bottom: 2em;
}

.terms-and-conditions-dialog-title {
  position: sticky;
  top: 0;
}

.agree-checkbox {
  justify-content: center;
}

.expense-rate-note {
  color: #4d4d4d !important;
  font-size: 12px;
}

.additional-note {
  color: #4d4d4d !important;
  font-size: 12px;
}

.expense-note {
  color: #767676 !important;
  font-size: 16px;
}

.product-information {
  font-weight: 500;
  font-size: 19px;
  color: #292e35 !important;
}

.product-description {
  font-size: 16px;
}

.buttonSize {
  max-width: 160px;
  max-height: 30px;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: content;
}

@media only screen and (max-width: 650px) {
  .v-flex-groupDialog {
    padding: 0;
  }
}

.vehicle-selection-step {
  max-height: 65vh;
  overflow-y: auto;
  padding: 0;
}

@media only screen and (max-height: 1050px) {
  .vehicle-selection-step {
    max-height: 65vh;
  }
}

@media only screen and (max-height: 825px) {
  .vehicle-selection-step {
    max-height: 50vh;
  }
}

.downloadAlignment {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.childAccount {
  text-align: left;
}
</style>
