// Configure FCSUI Notification
import FcsUI, { FcsNotifications } from 'fcsui';
import moment from 'moment';
import FcsUIWrapper from '@/components/FcsUIWrapper';

export default {
  install(Vue, options) {
    const installFcsUi = (config) => {
      Vue.use(FcsUI);

      const timestampFormatter = (timestamp) => {
        return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
      };
      const getUserId = () => {
        return options.store.getters['auth/getUserId'];
      };

      Vue.use(FcsNotifications, {
        timestampFormatter,
        store: options.store,
        getUserId,
        notificationServiceBaseUrl: config.notificationServiceBaseUrl,
        realTimeNotificationServiceBaseUrl:
          config.realTimeNotificationServiceBaseUrl,
      });
    };

    Vue.component(FcsUIWrapper.name, FcsUIWrapper);
    const defaultConfig = {
      notificationServiceBaseUrl:
        process.env.VUE_APP_NOTIFICATION_SERVICE_BASE_URL,
      realTimeNotificationServiceBaseUrl:
        process.env.VUE_APP_REAL_TIME_NOTIFICATION_SERVICE_BASE_URL,
    };
    if (options.store.get('configuration/areNotificationsEnabled')) {
      if (process.env.NODE_ENV === 'production') {
        const host = window.location.host;
        if (host.includes('fleetaccount-e2e')) {
          defaultConfig.notificationServiceBaseUrl = options.store
            .get('configuration/notificationServiceBaseUrl')
            .replace('{{E2E_HOST}}', host);
          defaultConfig.realTimeNotificationServiceBaseUrl = options.store
            .get('configuration/realTimeNotificationServiceBaseUrl')
            .replace('{{E2E_HOST}}', host);
        } else {
          defaultConfig.notificationServiceBaseUrl = options.store.get(
            'configuration/notificationServiceBaseUrl'
          );
          defaultConfig.realTimeNotificationServiceBaseUrl = options.store.get(
            'configuration/realTimeNotificationServiceBaseUrl'
          );
        }
        installFcsUi(defaultConfig);
      } else {
        installFcsUi(defaultConfig);
      }
    }
  },
};
